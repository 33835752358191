import cl from './ServiceDeleteButton.module.css'
import { ReactComponent as DeleteServiceIconText } from "../../../icons/DeleteService.svg"
import { useState } from 'react';
import ModalWindowToConfirmServiceDeletion from './modalWindowToConfirmServiceDeletion/ModalWindowToConfirmServiceDeletion';
import ModalUniversal from '../../universalComponents/modalUniversal/ModalUniversal';

const ServiceDeleteButton = ({ handleDeleteService }) => {
    const [showModal, setShowModal] = useState(false);

    const handleDelete = () => {
        setShowModal(false);
        handleDeleteService();
    };

    return (
        <div className={cl.container}>
            <button className={cl.btn} onClick={() => setShowModal(true)}>
                <DeleteServiceIconText />
            </button>
            {/* {showModal && <ModalWindowToConfirmServiceDeletion onConfirm={handleDelete} onCancel={() => setShowModal(false)} />} */}

            {showModal &&
                <ModalUniversal
                    text={'Вы уверены, что хотите удалить услугу?'}
                    setIsVisible={setShowModal}
                    onConfirm={handleDeleteService}
                    confirmText={'Удалить'}
                    cancelText={'Назад'}
                />
            }
        </div>
    )
}

export default ServiceDeleteButton