import cl from "./ServicesButton.module.css"
import { ReactComponent as DrillIn } from "../../../icons/Drill-in.svg"
import { HeadlineBody } from "../../../textStyles/TextStyleComponents"
import { useNavigate } from "react-router-dom"

const ServicesButton = ({ body, id }) => {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate()
    const handleOnClick = () => {
        navigate(`/addingOrChangeService/${id}`);
    }

    const iconStyle = {
        color: tg.colorScheme === "light" ? "#3C3C43" : "#EBEBF5"
      };

    return (
        <div className={cl.container}>
            <button className={cl.btn} onClick={handleOnClick}>
                <HeadlineBody className={cl.btnText}>{body}</HeadlineBody>
                <DrillIn style={iconStyle}/>
            </button>
        </div>
    )
}

export default ServicesButton