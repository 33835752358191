// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TitleForSingleDayOfSchedule_container__Zll\\+W {
    height: 60px;
    display: flex;
    align-items: center;
    background-color: var(--tg-theme-bg-color);
    /* background-color: aqua; */
    justify-content: space-between;
    padding: 0 16px;
    margin-bottom: 10px;
}

.TitleForSingleDayOfSchedule_customSwitchDark__uroHq {
    background-color: rgba(120, 120, 128, 0.32) !important;
    border: 1px solid rgba(120, 120, 128, 0.04) !important;
}

.TitleForSingleDayOfSchedule_customSwitchLight__31Edm {
    background-color: rgba(120, 120, 128, 0.16) !important;
    border: 1px solid rgba(120, 120, 128, 0.02) !important;
    box-shadow: none;
}

.TitleForSingleDayOfSchedule_customSwitchActive__AulMA {
    border: 1px solid #4bd964 !important; 
}`, "",{"version":3,"sources":["webpack://./src/components/sсhedule/listOfDaysForSсhedule/singleDayOfSchedulePage/titleForSingleDayOfSchedule/TitleForSingleDayOfSchedule.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,0CAA0C;IAC1C,4BAA4B;IAC5B,8BAA8B;IAC9B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,sDAAsD;IACtD,sDAAsD;AAC1D;;AAEA;IACI,sDAAsD;IACtD,sDAAsD;IACtD,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".container {\n    height: 60px;\n    display: flex;\n    align-items: center;\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: aqua; */\n    justify-content: space-between;\n    padding: 0 16px;\n    margin-bottom: 10px;\n}\n\n.customSwitchDark {\n    background-color: rgba(120, 120, 128, 0.32) !important;\n    border: 1px solid rgba(120, 120, 128, 0.04) !important;\n}\n\n.customSwitchLight {\n    background-color: rgba(120, 120, 128, 0.16) !important;\n    border: 1px solid rgba(120, 120, 128, 0.02) !important;\n    box-shadow: none;\n}\n\n.customSwitchActive {\n    border: 1px solid #4bd964 !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TitleForSingleDayOfSchedule_container__Zll+W`,
	"customSwitchDark": `TitleForSingleDayOfSchedule_customSwitchDark__uroHq`,
	"customSwitchLight": `TitleForSingleDayOfSchedule_customSwitchLight__31Edm`,
	"customSwitchActive": `TitleForSingleDayOfSchedule_customSwitchActive__AulMA`
};
export default ___CSS_LOADER_EXPORT___;
