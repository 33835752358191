// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SingleDayOfSchedulePage_container__I9jYx {
    margin: 0 -15px;
    margin-top: -8px;
}

.SingleDayOfSchedulePage_hintText__qucRb {
    color: var(--tg-theme-hint-color);
    margin-left: 13px;
}`, "",{"version":3,"sources":["webpack://./src/components/sсhedule/listOfDaysForSсhedule/singleDayOfSchedulePage/SingleDayOfSchedulePage.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iCAAiC;IACjC,iBAAiB;AACrB","sourcesContent":[".container {\n    margin: 0 -15px;\n    margin-top: -8px;\n}\n\n.hintText {\n    color: var(--tg-theme-hint-color);\n    margin-left: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SingleDayOfSchedulePage_container__I9jYx`,
	"hintText": `SingleDayOfSchedulePage_hintText__qucRb`
};
export default ___CSS_LOADER_EXPORT___;
