import templatesStore from "../../../../store/TemplatesStore";
import ButtonOfTemplatesForSchedule from "./buttonOfTemplatesForSchedule/ButtonOfTemplatesForSchedule";

const ListOfTemplatesForSchedule = () => {
    return (
        <div>
            {templatesStore.templatesData.map((template, index) => (
                <div key={index}>
                    <ButtonOfTemplatesForSchedule
                        name={template.name}
                        id={template.id}
                        isLast={index !== templatesStore.templatesData.length - 1}
                    />
                </div>
            ))}
        </div>
    );
};

export default ListOfTemplatesForSchedule;