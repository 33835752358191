// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServiceDuration_container__8oWm0 {
    margin-bottom: 10px;
}

.ServiceDuration_ttl__71J-- {
    color: var(--tg-theme-hint-color);
    margin-left: 16px;
}

.ServiceDuration_inptContainer__1Ngzu {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    background-color: var(--tg-theme-bg-color);
    /* background-color: aqua; */
    margin-top: 7px;
}

.ServiceDuration_inpt__8LILm {
    position: relative;
    background-color: var(--tg-theme-bg-color);
    /* background-color: aqua; */
    height: 44px;
    width: 50%;
    border: none;
    color: var(--tg-theme-text-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
    outline: none;
    padding-left: 16px;
    padding-right: 36px;
}

.ServiceDuration_inpt__8LILm::placeholder {
    color: var(--tg-theme-hint-color);
}

.ServiceDuration_inptHours__5P\\+6y {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.ServiceDuration_inptMinutes__FGX2E {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.ServiceDuration_separator__I4P7V {
    border-left: 1px solid var(--tg-theme-secondary-bg-color);
    /* border-left: 1px solid black; */
    margin-top: 13.5px;
    margin-bottom: 13.5px;
    /* height: calc(100% - 27px); */
}`, "",{"version":3,"sources":["webpack://./src/components/addingOrChangeService/serviceDuration/ServiceDuration.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,0CAA0C;IAC1C,4BAA4B;IAC5B,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,0CAA0C;IAC1C,4BAA4B;IAC5B,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;IAC7B,aAAa;IACb,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,+BAA+B;IAC/B,4BAA4B;AAChC;;AAEA;IACI,gCAAgC;IAChC,6BAA6B;AACjC;;AAEA;IACI,yDAAyD;IACzD,kCAAkC;IAClC,kBAAkB;IAClB,qBAAqB;IACrB,+BAA+B;AACnC","sourcesContent":[".container {\n    margin-bottom: 10px;\n}\n\n.ttl {\n    color: var(--tg-theme-hint-color);\n    margin-left: 16px;\n}\n\n.inptContainer {\n    display: flex;\n    justify-content: space-between;\n    border-radius: 10px;\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: aqua; */\n    margin-top: 7px;\n}\n\n.inpt {\n    position: relative;\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: aqua; */\n    height: 44px;\n    width: 50%;\n    border: none;\n    color: var(--tg-theme-text-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n    outline: none;\n    padding-left: 16px;\n    padding-right: 36px;\n}\n\n.inpt::placeholder {\n    color: var(--tg-theme-hint-color);\n}\n\n.inptHours {\n    border-bottom-left-radius: 10px;\n    border-top-left-radius: 10px;\n}\n\n.inptMinutes {\n    border-bottom-right-radius: 10px;\n    border-top-right-radius: 10px;\n}\n\n.separator {\n    border-left: 1px solid var(--tg-theme-secondary-bg-color);\n    /* border-left: 1px solid black; */\n    margin-top: 13.5px;\n    margin-bottom: 13.5px;\n    /* height: calc(100% - 27px); */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ServiceDuration_container__8oWm0`,
	"ttl": `ServiceDuration_ttl__71J--`,
	"inptContainer": `ServiceDuration_inptContainer__1Ngzu`,
	"inpt": `ServiceDuration_inpt__8LILm`,
	"inptHours": `ServiceDuration_inptHours__5P+6y`,
	"inptMinutes": `ServiceDuration_inptMinutes__FGX2E`,
	"separator": `ServiceDuration_separator__I4P7V`
};
export default ___CSS_LOADER_EXPORT___;
