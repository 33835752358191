import cl from './ServicePrice.module.css'
import { Subheadline2Semibold } from '../../../textStyles/TextStyleComponents';
import { useRef } from 'react';
import validationStore from '../../../store/ValidationStore';

const ServicePrice = ({ servicePrice, setServicePrice }) => {

    const inputRef = useRef(null);

    const handleChangeServicePrice = (e) => {
        const value = e.target.value;
        // Удаляем все кроме чисел и точки, и символ рубля
        const numericValue = value.replace(/[^0-9.]/g, '');

        if (numericValue.length > validationStore.maxServicePriceInputLength) {
            setTimeout(() => {
                inputRef.current.setSelectionRange(servicePrice.length - 2, servicePrice.length - 2);
            }, 0);
            return;
        }

        const cursorPosition = e.target.selectionStart;

        setServicePrice(numericValue ? numericValue + " ₽" : '');

        setTimeout(() => {
            if (inputRef.current && numericValue) {
                inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
            }
        }, 0);
    }

    const handleKeyDown = (e) => {
        const { selectionStart, selectionEnd } = e.target;

        // Запретить перемещение курсора за пределы разрешенного диапазона с клавиатуры
        if ((selectionStart >= servicePrice.length - 2 && selectionEnd >= servicePrice.length - 2) &&
            (e.key === "ArrowRight" || e.key === "End")) {
            e.preventDefault();
        }
        if ((selectionStart <= servicePrice.length - 2 && selectionEnd <= servicePrice.length - 2) &&
            (e.key === "ArrowLeft" || e.key === "Home")) {
            e.preventDefault();
        }
    };

    const handleClick = (e) => {
        // Предотвратить установку курсора за последними двумя символами при клике мышью
        const { selectionStart, selectionEnd } = inputRef.current;

        if (selectionStart > servicePrice.length - 2 || selectionEnd > servicePrice.length - 2) {
            inputRef.current.setSelectionRange(servicePrice.length - 2, servicePrice.length - 2);
            e.preventDefault(); // Предотвратить дальнейшее событие клика
        }
    };

    return (
        <div className={cl.container}>
            <Subheadline2Semibold className={cl.ttl}>Цена</Subheadline2Semibold>            
                <input
                    inputMode="numeric"
                    className={cl.priceInput}
                    placeholder={"Цена ₽"}
                    value={servicePrice}
                    onChange={handleChangeServicePrice}

                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    onClick={handleClick}
                    onMouseUp={handleClick}
                />
        </div>
    );
};

export default ServicePrice