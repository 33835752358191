// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BotSettings_instruction__fLX4m {
    padding: 14px 0;
}

.BotSettings_link__lgmQ\\+ {
    color: var(--tg-theme-link-color); 
    text-decoration: none; /* Убирает нижнее подчеркивание */
}

.BotSettings_text__8sSVD {
    padding-bottom: 14px;
}`, "",{"version":3,"sources":["webpack://./src/pages/botSettings/BotSettings.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,iCAAiC;IACjC,qBAAqB,EAAE,iCAAiC;AAC5D;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".instruction {\n    padding: 14px 0;\n}\n\n.link {\n    color: var(--tg-theme-link-color); \n    text-decoration: none; /* Убирает нижнее подчеркивание */\n}\n\n.text {\n    padding-bottom: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instruction": `BotSettings_instruction__fLX4m`,
	"link": `BotSettings_link__lgmQ+`,
	"text": `BotSettings_text__8sSVD`
};
export default ___CSS_LOADER_EXPORT___;
