import { Footnote, HeadlineBody } from "../../../textStyles/TextStyleComponents";
import cl from "./ServicesBlocks.module.css"
import getStore from "../../../store/GetStore";
import ServicesButton from "../servicesButton/ServicesButton";
import { observer } from "mobx-react-lite";

const ServicesBlocks = observer(() => {
    const servicesEntries = Object.entries(getStore.servicesData);

    if (!getStore.isServicesDataLoaded) {
        return null;
    }

    return (
        <div>
            {servicesEntries.length !== 0
                ? servicesEntries.map(([category, items]) => (
                    <div key={category} className={cl.block}>
                        <Footnote className={cl.blockName}>{category}</Footnote>
                        <div className={cl.servicesButtonContainer}>
                            {items.map((service, index) => (
                                <div key={service.id} className={cl.serviceItemContainer}>
                                    <ServicesButton
                                        body={service.name}
                                        id={service.id}
                                    />
                                    {index !== items.length - 1 && <div className={cl.borderBottom}></div>}
                                </div>
                            ))}
                        </div>
                    </div>
                ))

                : <div className={cl.errorMessage}>
                    <HeadlineBody>У вас нет ни одной услуги, давайте добавим первую!</HeadlineBody>
                </div>
            }
        </div>
    )
});

export default ServicesBlocks