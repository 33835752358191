// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeletePhotoButton_container__Sczki {
    margin-top: 19px;
}

.DeletePhotoButton_btn__r\\+6J7 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 69, 48, 0.1);
    /* background-color: aqua; */
    height: 32px;
    border: none;
    
    color: #FF3B30;
    
    border-radius: 40px;    

    padding: 0 10px;

    width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/addingOrChangeService/servicePhotosEditor/deletePhotoButton/DeletePhotoButton.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,wCAAwC;IACxC,4BAA4B;IAC5B,YAAY;IACZ,YAAY;;IAEZ,cAAc;;IAEd,mBAAmB;;IAEnB,eAAe;;IAEf,YAAY;AAChB","sourcesContent":[".container {\n    margin-top: 19px;\n}\n\n.btn {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: rgba(255, 69, 48, 0.1);\n    /* background-color: aqua; */\n    height: 32px;\n    border: none;\n    \n    color: #FF3B30;\n    \n    border-radius: 40px;    \n\n    padding: 0 10px;\n\n    width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DeletePhotoButton_container__Sczki`,
	"btn": `DeletePhotoButton_btn__r+6J7`
};
export default ___CSS_LOADER_EXPORT___;
