import React from "react";
import cl from "./ButtonUniversal.module.css"
import { HeadLineSemibold } from "../../../textStyles/TextStyleComponents";
import { ReactComponent as Disclosure } from "../../../icons/Disclosure.svg"

const ButtonUniversal = ({ title, body, onClick }) => {
    return (
        <div className={cl.container}>
            <HeadLineSemibold className={cl.ttl}>{title}</HeadLineSemibold>
            <button className={cl.btn} onClick={onClick}>                
                {body}
                <Disclosure/>
            </button>
        </div>
    )
}

export default ButtonUniversal