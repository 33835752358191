import cl from './DeletePhotoButton.module.css'
import { ReactComponent as DeletePhotoIcon } from "../../../../icons/DeletePhoto.svg";
import { Subheadline1 } from '../../../../textStyles/TextStyleComponents';

const DeletePhotoButton = ({ body, activeIndex, removePhotoAtIndex }) => {
    return (
        <div className={cl.container} onClick={() => removePhotoAtIndex(activeIndex)}>
            <button className={cl.btn}>
                <DeletePhotoIcon/>
                <Subheadline1>{body}</Subheadline1>
            </button>
        </div>
    )
}

export default DeletePhotoButton