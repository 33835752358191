import cl from './ServiceCategory.module.css'
import { Subheadline2Semibold } from '../../../textStyles/TextStyleComponents';
import validationStore from '../../../store/ValidationStore';
import { useState } from 'react';
import { HeadlineBody } from '../../../textStyles/TextStyleComponents';

const ServiceCategory = ({ serviceCategory, setServiceCategory, categories }) => {
    const tg = window.Telegram.WebApp;
    
    const [showDropdown, setShowDropdown] = useState(false);

    const handleSelectCategory = (category) => {
        setServiceCategory(category);
        setShowDropdown(false);
    };

    const inputCategoryOnFocus = () => {
        if (categories.length !== 0) setShowDropdown(true);
    }

    return (
        <div className={cl.container}>
            <Subheadline2Semibold className={cl.ttl}>Категория</Subheadline2Semibold>
            <input
                className={cl.inpt}
                placeholder={'Например, маникюр'}
                value={serviceCategory}
                onChange={(e) => validationStore.handleReguralInputChange(e, setServiceCategory)}

                onFocus={inputCategoryOnFocus}
                onBlur={() => setShowDropdown(false)}
            />

            {showDropdown && (
                <div className={`${cl.dropdown} ${tg.colorScheme === 'dark' ? cl.darkBorder : cl.lightBorder}`}>
                    {categories.filter(category => category.trim() !== '').map((category, index) => (
                        <div
                            key={index}
                            onMouseDown={() => handleSelectCategory(category)}
                        >
                            <div className={`${cl.dropdownItem} ${index === categories.length - 1 ? cl.noBorderBottom : ''}`}>
                                <HeadlineBody>{category}</HeadlineBody>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ServiceCategory