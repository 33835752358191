import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddServiceButton from "../../components/servicesPage/addServiceButton/AddServiceButton";
import ServicesBlocks from "../../components/servicesPage/servicesBlocks/ServicesBlocks";
import HeaderUniversal from "../../components/universalComponents/headerUniversal/HeaderUniversal";
import cl from "./ServicesPage.module.css"
import getStore from "../../store/GetStore";
import axios from "axios";
import firstTwoPageStore from "../../store/FirstTwoPageStore";

const ServicesPage = () => {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate();

    useEffect(() => {
        if (!firstTwoPageStore.hasFetchedSelfEmployedData) {
            navigate('*')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getServicesData = async () => {
            const botUsername = localStorage.getItem('botUsername')
            try {
                const response = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/services`);
                getStore.servicesData = (response.data);
                if (!getStore.isServicesDataLoaded) getStore.isServicesDataLoaded = true;
                console.log("Загружены данны о сервисах");
            } catch (error) {
                console.error('Ошибка загрузки данных о сервисах', error);
                const errorCode = error.response?.status || 500;
                const errorMessage = error.response?.statusText || 'Internal Server Error';
                navigate('/errorPage', { state: { errorCode, errorMessage } });
            }
        }

        getServicesData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleBackButtonOnClick = () => {
            navigate("*");
        }

        const handleMainButtonOnClick = () => {
            navigate("*");
        }

        tg.MainButton.enable();

        if (!tg.BackButton.isVisible) tg.BackButton.show();
        if (!tg.MainButton.isVisible) tg.MainButton.show();

        tg.BackButton.onClick(handleBackButtonOnClick);
        tg.MainButton.onClick(handleMainButtonOnClick);

        if (tg.MainButton.color !== tg.themeParams.button_color) {
            tg.MainButton.color = tg.themeParams.button_color
            tg.MainButton.textColor = tg.themeParams.button_text_color
        }

        return () => {
            tg.BackButton.offClick(handleBackButtonOnClick);
            tg.MainButton.offClick(handleMainButtonOnClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <HeaderUniversal>Мои услуги</HeaderUniversal>
            <ServicesBlocks />
            <AddServiceButton />
        </div>
    )
}

export default ServicesPage