import { Subheadline2Semibold } from '../../../textStyles/TextStyleComponents';
import cl from './ServiceName.module.css'
import validationStore from '../../../store/ValidationStore';

const ServiceName = ({ serviceName, setServiceName }) => {


    return (
        <div className={cl.container}>
            <Subheadline2Semibold className={cl.ttl}>Название</Subheadline2Semibold>
            <input
                className={cl.inpt}
                placeholder={'Например, классический маникюр'}
                value={serviceName}
                onChange={(e) => validationStore.handleReguralInputChange(e, setServiceName)}
            />
        </div>
    );
};

export default ServiceName;