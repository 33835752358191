import cl from "./ButtonOfTemplatesForSchedule.module.css"
import { ReactComponent as DrillIn } from "../../../../../icons/Drill-in.svg"
import { HeadlineBody } from "../../../../../textStyles/TextStyleComponents"
import { useNavigate } from "react-router-dom"

const ButtonOfTemplatesForSchedule = ({ name, id, isLast }) => {
    const navigate = useNavigate();

    // Функция для перехода на страницу с конкретным шаблоном
    const handleNavigate = () => {
        navigate(`/template/${id}`);
    };

    return (
        <div className={cl.container}>
            <button className={cl.btn} onClick={handleNavigate}>
                <HeadlineBody className={cl.btnText}>{name}</HeadlineBody>
                <DrillIn/>
                {isLast && (<div className={cl.borderBottom}></div>)}
            </button>
        </div>
    )
}

export default ButtonOfTemplatesForSchedule