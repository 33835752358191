import cl from "./SingleDayOfSchedulePage.module.css"
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TitleForSingleDayOfSchedule from "./titleForSingleDayOfSchedule/TitleForSingleDayOfSchedule";
import getStore from "../../../../store/GetStore";
import { observer } from "mobx-react-lite";
import SelectTemplate from "./selectTemplate/SelectTemplate";
import ListGapsOfSchedule from "./listGapsOfSchedule/ListGapsOfSchedule";
import { Footnote } from "../../../../textStyles/TextStyleComponents";
import ModalUniversal from "../../../universalComponents/modalUniversal/ModalUniversal";
import firstTwoPageStore from "../../../../store/FirstTwoPageStore";

const SingleDayOfSchedulePage = observer(() => {
    const { date } = useParams();
    const navigate = useNavigate();
    const tg = window.Telegram.WebApp;
    const timeInterval = 15;

    const [showGapsValidationModal, setShowGapsValidationModal] = useState(false);
    const [showBusyRecordingCellsModal, setShowBusyRecordingCellsModal] = useState(false);

    useEffect(() => {
        if (!firstTwoPageStore.hasFetchedSelfEmployedData) {
            navigate('*')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // console.log(date)

    let currentDay = getStore.timeData.find((item) => item.date === date);

    if (!currentDay) {
        currentDay = {
            date: date,
            status: 'REST',            
            templateId: 1,
            recordingCells: []
        };
    }

    const calculateGaps = (cells) => {
        if (cells.length === 0) return [];

        let gaps = [];
        let start = cells[0].time;
        let end = null;

        for (let i = 1; i < cells.length; i++) {
            const current = cells[i];
            const previous = cells[i - 1];

            const currentTime = new Date(`1970-01-01T${current.time}:00`);
            const previousTime = new Date(`1970-01-01T${previous.time}:00`);
            const diff = (currentTime - previousTime) / 1000 / 60;

            if (diff > timeInterval) {
                end = new Date(previousTime.getTime() + timeInterval * 60000);
                gaps.push({ start, end: end.toTimeString().substring(0, 5) });
                start = current.time;
            }
        }

        const lastEnd = new Date(new Date(`1970-01-01T${cells[cells.length - 1].time}:00`).getTime() + timeInterval * 60000);
        gaps.push({ start, end: lastEnd.toTimeString().substring(0, 5) });

        return gaps;
    };

    const areGapsValid = (gaps) => {
        const sortedGaps = [...gaps].sort((a, b) => a.start.localeCompare(b.start));

        for (let i = 0; i < sortedGaps.length; i++) {
            // Проверяем, что время начала строго меньше времени окончания для каждого промежутка
            if (sortedGaps[i].start >= sortedGaps[i].end) {
                return false; // Некорректный промежуток
            }

            // Если это не первый промежуток, проверяем, чтобы не было пересечений
            if (i > 0 && sortedGaps[i].start < sortedGaps[i - 1].end) {
                return false; // Найдено пересечение
            }
        }
        return true; // Все промежутки корректны и не пересекаются
    };

    const sortGapsByTime = (gaps) => {
        return gaps.sort((a, b) => a.start.localeCompare(b.start));
    };

    const gapsToRecordingCells = (gaps, previousCells) => {
        const recordingCells = [];
        const statusMap = new Map();

        // Заполним statusMap для быстрого поиска статуса по времени
        previousCells.forEach(cell => {
            statusMap.set(cell.time, cell.status);
        });

        gaps.forEach(gap => {
            let [startHours, startMinutes] = gap.start.split(':').map(Number);
            let [endHours, endMinutes] = gap.end.split(':').map(Number);

            while (startHours < endHours || (startHours === endHours && startMinutes < endMinutes)) {
                const time = `${String(startHours).padStart(2, '0')}:${String(startMinutes).padStart(2, '0')}`;
                recordingCells.push({
                    time,
                    status: statusMap.get(time) || 'FREE'  // Если есть статус для этого времени, берем его, иначе 'FREE'
                });
                startMinutes += 15;
                if (startMinutes >= 60) {
                    startMinutes = 0;
                    startHours += 1;
                }
            }
        });
        return recordingCells;
    };


    const [day, setDay] = useState(currentDay);
    const [gaps, setGaps] = useState(calculateGaps(day.recordingCells));
    const [selectedTemplateId, setSelectedTemplateId] = useState(day.templateId);

    useEffect(() => {
        const handleBackButtonOnClick = () => {
            navigate("/sсhedule");
        }

        const handleMainButtonOnClick = () => {
            // Сортируем промежутки для точности проверок
            const sortedGaps = sortGapsByTime(gaps);

            // Проверка на конфликты с текущими занятыми слотами
            const conflictIntervals = day.recordingCells
                .filter(cell => cell.status === 'BUSY')
                .filter(cell => !sortedGaps.some(gap => cell.time >= gap.start && cell.time < gap.end));

            if (conflictIntervals.length > 0) {
                setShowBusyRecordingCellsModal(true);
                return;
            }

            if (areGapsValid(gaps)) {
                day.templateId = selectedTemplateId;
                day.recordingCells = gapsToRecordingCells(sortedGaps, day.recordingCells);
                getStore.updateOrAddDay(day);
                navigate("/sсhedule");
            } else {
                setShowGapsValidationModal(true);
                return;
            }
        };

        tg.BackButton.onClick(handleBackButtonOnClick);

        tg.MainButton.setText("Готово");

        tg.MainButton.onClick(handleMainButtonOnClick);

        return () => {
            tg.BackButton.offClick(handleBackButtonOnClick);
            tg.MainButton.offClick(handleMainButtonOnClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [day, gaps, selectedTemplateId]);

    return (
        <div className={cl.container}>
            <TitleForSingleDayOfSchedule stateDay={day} setDay={setDay} />
            <SelectTemplate selectedTemplateId={selectedTemplateId} setSelectedTemplateId={setSelectedTemplateId} setGaps={setGaps} />
            <ListGapsOfSchedule gaps={gaps} setGaps={setGaps} setSelectedTemplateId={setSelectedTemplateId} />
            <Footnote className={cl.hintText}>Настройте свое расписание в течение дня</Footnote>


            {showGapsValidationModal &&
                <ModalUniversal
                    text={'Промежутки веремени не должны пересекаться'}
                    setIsVisible={setShowGapsValidationModal}
                    cancelText={'Окей'}
                />
            }

            {showBusyRecordingCellsModal &&
                <ModalUniversal
                    text={`На убираемое время есть запись. Чтобы изменить расписание - отмените ее`}
                    setIsVisible={setShowBusyRecordingCellsModal}
                    cancelText={'Окей'}
                />
            }
        </div>
    )
})

export default SingleDayOfSchedulePage
