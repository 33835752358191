import DayForShedule from "./dayForSсhedule/DayForSсhedule";
import cl from "./ListOfDaysForSсhedule.module.css"

const ListOfDaysForSсhedule = () => {

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const currentYear = currentDate.getFullYear();



    const getShortDayOfWeek = (year, month, day) => {
        const date = new Date(year, month, day);
        return new Intl.DateTimeFormat('ru-RU', { weekday: 'short' }).format(date).toLowerCase();
    };

    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const generateCalendarData = (startYear, startMonth, startDay, activeCount) => {
        const calendarData = [];
        let year = startYear;
        let month = startMonth;
        let day = startDay;
        let activeDaysGenerated = 0;



        while (activeDaysGenerated < activeCount) {
            const daysInMonth = getDaysInMonth(year, month);
            for (; day <= daysInMonth && activeDaysGenerated < activeCount; day++) {
                const shortDayOfWeek = getShortDayOfWeek(year, month, day);
                calendarData.push({ day, shortDayOfWeek, month, year, isDisabled: false });
                activeDaysGenerated++;
            }
            day = 1;
            month = (month + 1) % 12;
            if (month === 0) year++;
        }

        return calendarData;
    };

    const calendarData = generateCalendarData(currentYear, currentMonth, currentDay, 60);

    return (
        <div className={cl.container}>
            {calendarData.map((date, index) => (
                <DayForShedule
                    key={index}
                    date={date}
                    isLast={index === calendarData.length - 1}                    
                />
            ))}
        </div>
    )
}

export default ListOfDaysForSсhedule