// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DayForSсhedule_container__vkRyJ {
    min-height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 16px;
}

.DayForSсhedule_customSwitchDark__Zf7gm {
    background-color: rgba(120, 120, 128, 0.32) !important;
    border: 1px solid rgba(120, 120, 128, 0.04) !important;
}

.DayForSсhedule_customSwitchLight__ktxbR {
    background-color: rgba(120, 120, 128, 0.16) !important;
    border: 1px solid rgba(120, 120, 128, 0.02) !important;
    box-shadow: none;
}

.DayForSсhedule_customSwitchActive__bwCsJ {
    border: 1px solid #4bd964 !important; 
}

.DayForSсhedule_borderBottom__hii6Q {
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 0;
    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
    pointer-events: none;
}

.DayForSсhedule_templateName__0jtLO {
    color: #FF9500;
}

.DayForSсhedule_timeGaps__DR60e {
    padding-top: 8px;
    padding-bottom: 11px;
}

.DayForSсhedule_timeGap__kZTah {
    color: var(--tg-theme-hint-color);
}

.DayForSсhedule_data__T6eSd {
    padding-top: 11px;
}`, "",{"version":3,"sources":["webpack://./src/components/sсhedule/listOfDaysForSсhedule/dayForSсhedule/DayForS%D1%81hedule.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,sDAAsD;IACtD,sDAAsD;AAC1D;;AAEA;IACI,sDAAsD;IACtD,sDAAsD;IACtD,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,QAAQ;IACR,2DAA2D;IAC3D,oBAAoB;AACxB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".container {\n    min-height: 53px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    position: relative;\n    padding: 0 16px;\n}\n\n.customSwitchDark {\n    background-color: rgba(120, 120, 128, 0.32) !important;\n    border: 1px solid rgba(120, 120, 128, 0.04) !important;\n}\n\n.customSwitchLight {\n    background-color: rgba(120, 120, 128, 0.16) !important;\n    border: 1px solid rgba(120, 120, 128, 0.02) !important;\n    box-shadow: none;\n}\n\n.customSwitchActive {\n    border: 1px solid #4bd964 !important; \n}\n\n.borderBottom {\n    position: absolute;\n    bottom: 0;\n    left: 16px;\n    right: 0;\n    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);\n    pointer-events: none;\n}\n\n.templateName {\n    color: #FF9500;\n}\n\n.timeGaps {\n    padding-top: 8px;\n    padding-bottom: 11px;\n}\n\n.timeGap {\n    color: var(--tg-theme-hint-color);\n}\n\n.data {\n    padding-top: 11px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DayForSсhedule_container__vkRyJ`,
	"customSwitchDark": `DayForSсhedule_customSwitchDark__Zf7gm`,
	"customSwitchLight": `DayForSсhedule_customSwitchLight__ktxbR`,
	"customSwitchActive": `DayForSсhedule_customSwitchActive__bwCsJ`,
	"borderBottom": `DayForSсhedule_borderBottom__hii6Q`,
	"templateName": `DayForSсhedule_templateName__0jtLO`,
	"timeGaps": `DayForSсhedule_timeGaps__DR60e`,
	"timeGap": `DayForSсhedule_timeGap__kZTah`,
	"data": `DayForSсhedule_data__T6eSd`
};
export default ___CSS_LOADER_EXPORT___;
