// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalWindowToConfirmServiceDeletion_backdrop__Z8L3q {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 90;
}

.ModalWindowToConfirmServiceDeletion_dark__Dx2d9 {
    background-color: rgba(0, 0, 0, 0.6);
}

.ModalWindowToConfirmServiceDeletion_light__8tTbr {
    background-color: rgba(0, 0, 0, 0.4);
}

.ModalWindowToConfirmServiceDeletion_modal__tNY8V {
    background-color: var(--tg-theme-bg-color);

    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    width: 270px;
    height: 102px;
}

.ModalWindowToConfirmServiceDeletion_text__-vfxp {
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.ModalWindowToConfirmServiceDeletion_btnContaier__dVbE8 {    
    height: 44px;
    display: flex;
    justify-content: space-between;
}

.ModalWindowToConfirmServiceDeletion_btn__zW6pk {
    background-color: var(--tg-theme-bg-color);
    border: none;
    border-top: 1px solid var(--tg-theme-secondary-bg-color);
    width: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;

}

.ModalWindowToConfirmServiceDeletion_confirmBtn__u0Hi0 {
    color: #FF3B30;
    border-right: 1px solid var(--tg-theme-secondary-bg-color);
    border-bottom-left-radius: 14px;
}

.ModalWindowToConfirmServiceDeletion_cancelBtn__3Embv {
    color: var(--tg-theme-button-color);
    border-bottom-right-radius: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/addingOrChangeService/serviceDeleteButton/modalWindowToConfirmServiceDeletion/ModalWindowToConfirmServiceDeletion.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,0CAA0C;;IAE1C,mBAAmB;IACnB,yCAAyC;IACzC,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;AAEvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,0CAA0C;IAC1C,YAAY;IACZ,wDAAwD;IACxD,UAAU;IACV,oBAAoB;IACpB,uBAAuB;IACvB,mBAAmB;;AAEvB;;AAEA;IACI,cAAc;IACd,0DAA0D;IAC1D,+BAA+B;AACnC;;AAEA;IACI,mCAAmC;IACnC,gCAAgC;AACpC","sourcesContent":[".backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 90;\n}\n\n.dark {\n    background-color: rgba(0, 0, 0, 0.6);\n}\n\n.light {\n    background-color: rgba(0, 0, 0, 0.4);\n}\n\n.modal {\n    background-color: var(--tg-theme-bg-color);\n\n    border-radius: 14px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\n    width: 270px;\n    height: 102px;\n}\n\n.text {\n    height: 58px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    \n}\n\n.btnContaier {    \n    height: 44px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.btn {\n    background-color: var(--tg-theme-bg-color);\n    border: none;\n    border-top: 1px solid var(--tg-theme-secondary-bg-color);\n    width: 50%;\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n\n}\n\n.confirmBtn {\n    color: #FF3B30;\n    border-right: 1px solid var(--tg-theme-secondary-bg-color);\n    border-bottom-left-radius: 14px;\n}\n\n.cancelBtn {\n    color: var(--tg-theme-button-color);\n    border-bottom-right-radius: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `ModalWindowToConfirmServiceDeletion_backdrop__Z8L3q`,
	"dark": `ModalWindowToConfirmServiceDeletion_dark__Dx2d9`,
	"light": `ModalWindowToConfirmServiceDeletion_light__8tTbr`,
	"modal": `ModalWindowToConfirmServiceDeletion_modal__tNY8V`,
	"text": `ModalWindowToConfirmServiceDeletion_text__-vfxp`,
	"btnContaier": `ModalWindowToConfirmServiceDeletion_btnContaier__dVbE8`,
	"btn": `ModalWindowToConfirmServiceDeletion_btn__zW6pk`,
	"confirmBtn": `ModalWindowToConfirmServiceDeletion_confirmBtn__u0Hi0`,
	"cancelBtn": `ModalWindowToConfirmServiceDeletion_cancelBtn__3Embv`
};
export default ___CSS_LOADER_EXPORT___;
