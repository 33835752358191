import { useEffect, useState, useRef } from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"
import HeaderUniversal from "../../components/universalComponents/headerUniversal/HeaderUniversal"
import getStore from "../../store/GetStore"
import { useNavigate } from "react-router-dom"
import DualInputDualButton from "../../components/addingOrChangeService/dualInputDualButton/DualInputDualButton"
import ServiceDescription from "../../components/addingOrChangeService/serviceDescription/ServiceDescription"
import ServicePhotosEditor from "../../components/addingOrChangeService/servicePhotosEditor/ServicePhotosEditor"
import ServiceDeleteButton from "../../components/addingOrChangeService/serviceDeleteButton/ServiceDeleteButton"
import ModalUniversal from "../../components/universalComponents/modalUniversal/ModalUniversal"
import axios from "axios"
import _ from 'lodash';
import ServiceName from "../../components/addingOrChangeService/serviceName/ServiceName"
import ServiceCategory from "../../components/addingOrChangeService/serviceCategory/ServiceCategory"
import ServiceDuration from "../../components/addingOrChangeService/serviceDuration/ServiceDuration"
import ServicePrice from "../../components/addingOrChangeService/servicePrice/ServicePrice"
import firstTwoPageStore from "../../store/FirstTwoPageStore"


const AddingOrChangeService = observer(() => {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate()
    const { id } = useParams()
    const handleClickRef = useRef();

    const botUsername = localStorage.getItem('botUsername')

    const isAddingNewService = id === "new"; // Проверяем, добавляем ли мы новую услугу

    const [showBackButtonModal, setShowBackButtonModal] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalText, setErrorModalText] = useState('');

    useEffect(() => {
        if (!firstTwoPageStore.hasFetchedSelfEmployedData) {
            navigate('*')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Продолжите установку начальных значений для остальных полей...

    // function removeEmptyCategories(servicesData) {
    //     Object.keys(servicesData).forEach(category => {
    //         if (servicesData[category].length === 0) {
    //             delete servicesData[category];
    //         }
    //     });
    // }


    const service = Object.values(getStore.servicesData).flat().find(s => s.id === parseInt(id));

    if (!isAddingNewService && !service) { // Чтоб при перезагрузки страницы не выдавало service undefined
        return null;
    }

    const [serviceName, setServiceName] = useState(isAddingNewService ? "" : service.name);
    const [initialServiceName] = useState(isAddingNewService ? "" : service.name); // нужно для заголовка
    const [serviceCategory, setServiceCategory] = useState(isAddingNewService ? "" : service.category);
    const [serviceDuration, setServiceDuration] = useState(isAddingNewService ? "" : service.duration)
    const [servicePrice, setServicePrice] = useState(isAddingNewService ? "" : service.price + " ₽")
    const [serviceDescription, setServiceDescription] = useState(isAddingNewService ? "" : service.description === null ? "" : service.description)
    const [servicePhotos, setServicePhotos] = useState(isAddingNewService ? [] : service.photos)

    // const [originalServicePhotos] = useState(isAddingNewService ? null : _.cloneDeep(service.photos));
    const originalServicePhotos = isAddingNewService ? null : _.cloneDeep(service.photos);

    const updateServiceData = async () => {
        tg.MainButton.disable();

        if (isAddingNewService) {
            const requestData = {
                name: serviceName,
                category: serviceCategory,
                duration: parseInt(serviceDuration, 10),
                description: serviceDescription === '' ? null : serviceDescription,
                price: servicePrice.slice(0, -2),
                photos: servicePhotos
            }

            try {
                const response = await axios.post(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/services`, requestData);
                console.log('Ответ от сервера:', response.data);
                navigate("/servicesPage");
            } catch (error) {
                setErrorModalText('Не удалось добавить новую услугу. Попробуйте снова')
                setShowErrorModal(true);
                tg.MainButton.enable();
                if (error.response) {
                    // Сервер вернул ответ с кодом ошибки, который находится в пределах диапазона 2xx
                    const errorData = error.response.data;
                    console.error("Ошибка:", errorData.error);
                    console.error("Описание:", errorData.description);
                } else if (error.request) {
                    // Запрос был сделан, но ответа не было получено
                    console.error("Запрос был сделан, но ответа не было получено", error.request);
                } else {
                    // Произошла другая ошибка при настройке запроса
                    console.error("Ошибка:", error.message);
                }
            }

            // const servicesData = { ...getStore.servicesData };

            //     const newId = Math.max(...Object.values(servicesData).flat().map(s => s.id)) + 1; // Генерируем новый уникальный ID

            //     const newService = {
            //         id: newId,
            //         category: serviceCategory,
            //         name: serviceName,
            //         price: parseFloat(servicePrice),
            //         duration: parseInt(serviceDuration, 10),
            //         photos: servicePhotos,
            //         description: serviceDescription,
            //     };


            // if (!servicesData[serviceCategory]) {
            //     servicesData[serviceCategory] = []; // Создание категории, если необходимо
            // }
            // servicesData[serviceCategory].push(newService);
        } else {
            const photosToDelete = originalServicePhotos
                .filter(originalPhoto => !servicePhotos.some(photo => photo.id === originalPhoto.id))
                .map(photo => photo.id);

            const photosToAdd = servicePhotos
                .filter(photo => !originalServicePhotos.some(originalPhoto => originalPhoto.base64 === photo.base64))
                .map(photo => photo.base64);

            const patchData = {
                name: serviceName,
                category: serviceCategory,
                duration: parseInt(serviceDuration, 10),
                description: serviceDescription,
                price: servicePrice.slice(0, -2),
                photosToDelete: photosToDelete,
                photosToAdd: photosToAdd
            }

            try {
                console.log(patchData)
                const response = await axios.patch(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/services/${id}`, patchData);
                console.log("Статус записи успешно обновлен", response.data);
                navigate("/servicesPage");
            } catch (error) {
                setErrorModalText('Не удалось изменить данную услугу. Попробуйте снова')
                setShowErrorModal(true);
                tg.MainButton.enable();
                if (error.response) {
                    // Сервер вернул ответ с кодом ошибки, который находится в пределах диапазона 2xx
                    const errorData = error.response.data;
                    console.error("Ошибка:", errorData.error);
                    console.error("Описание:", errorData.description);
                } else if (error.request) {
                    // Запрос был сделан, но ответа не было получено
                    console.error("Запрос был сделан, но ответа не было получено", error.request);
                } else {
                    // Произошла другая ошибка при настройке запроса
                    console.error("Ошибка:", error.message);
                }
            }

            // Удаление услуги из всех категорий кроме актуальной
            // Object.keys(servicesData).forEach((category) => {
            //     servicesData[category] = servicesData[category].filter((s) => s.id !== service.id);
            // });

            // // Если после удаления какая-то категория пуста, удаляем категорию
            // Object.keys(servicesData).forEach((category) => {
            //     if (servicesData[category].length === 0) {
            //         delete servicesData[category];
            //     }
            // });

            // // Добавление услуги в новую или существующую категорию
            // if (!servicesData[serviceCategory]) {
            //     servicesData[serviceCategory] = []; // Создание новой категории, если необходимо
            // }

            // servicesData[serviceCategory].push({
            //     id: service.id, // Использование существующего ID услуги
            //     category: serviceCategory,
            //     name: serviceName,
            //     price: Number(servicePrice.slice(0, -2)),
            //     duration: serviceDuration, // Также преобразование в число
            //     photos: servicePhotos, // Сохранение существующих фото
            //     description: serviceDescription // Сохранение существующего описания
            // });

            // // Сортировка услуг в категории по id по возрастанию
            // Object.keys(servicesData).forEach((category) => {
            //     servicesData[category].sort((a, b) => a.id - b.id);
            // });
        }

        // Обновление состояния хранилища
        // getStore.setServicesData(servicesData);
    };

    const handleDeleteService = async () => {
        if (!isAddingNewService) {
            try {
                const response = await axios.delete(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/services/${id}`);
                navigate("/servicesPage");
                console.log(`Сервис с ID ${id} успешно удалён`, response.data);
            } catch (error) {
                setErrorModalText('Не удалось удалить данную услугу. Попробуйте снова')
                setShowErrorModal(true);
                console.error(`Ошибка удаления сервиса с ID ${id}`, error);
            }
        } else {
            navigate("/servicesPage");
        }


        // const serviceId = parseInt(id); // Преобразование id из строки в число
        // const servicesData = { ...getStore.servicesData }; // Создание копии данных услуг

        // // Перебор всех категорий для поиска и удаления услуги
        // Object.keys(servicesData).forEach(category => {
        //     const index = servicesData[category].findIndex(s => s.id === serviceId);
        //     if (index > -1) {
        //         // Удаление услуги из категории
        //         servicesData[category].splice(index, 1)
        //         // Проверка, остались ли услуги в категории
        //         if (servicesData[category].length === 0) {
        //             // Удаление пустой категории
        //             delete servicesData[category];
        //         }
        //     }
        // });

        // // Обновление состояния хранилища после удаления услуги
        // getStore.setServicesData(servicesData);

        // Возврат пользователя на страницу со списком услуг
    };

    const [categories, setCategories] = useState(Object.keys(getStore.servicesData));

    const confirmBackButtonOnClick = () => {
        navigate("/servicesPage");
    }

    useEffect(() => {
        const handleBackButtonOnClick = () => {
            setShowBackButtonModal(true);
        }

        tg.BackButton.onClick(handleBackButtonOnClick);

        return () => {
            tg.BackButton.offClick(handleBackButtonOnClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Обновляем текущее значение handleClickRef без потери ссылки на функцию
        const handleMainButtonOnClick = () => {
            updateServiceData();
        };

        tg.MainButton.onClick(handleMainButtonOnClick);

        // Функция очистки
        return () => {
            tg.MainButton.offClick(handleMainButtonOnClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceName, serviceCategory, servicePrice, serviceDescription, serviceDuration, servicePhotos]);

    useEffect(() => {
        tg.MainButton.disable();

        if (tg.colorScheme === "light") {
            tg.MainButton.color = "#e8e8e9";
            tg.MainButton.textColor = "#b9b9ba";
        } else {
            tg.MainButton.color = "#2f2f2f";
            tg.MainButton.textColor = "#606060";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (serviceName && serviceDuration && servicePrice && serviceCategory) {
            if (!tg.MainButton.isActive) {
                tg.MainButton.enable()
            }
            if (tg.MainButton.color !== tg.themeParams.button_color) {
                tg.MainButton.color = tg.themeParams.button_color
                tg.MainButton.textColor = tg.themeParams.button_text_color
            }
        } else {
            tg.MainButton.disable();
            if (tg.colorScheme === "light") {
                tg.MainButton.color = "#e8e8e9";
                tg.MainButton.textColor = "#b9b9ba";
            } else {
                tg.MainButton.color = "#2f2f2f";
                tg.MainButton.textColor = "#606060";
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceName, servicePrice, serviceDuration, serviceCategory])

    // useEffect(() => {
    //     // Обновляем текущее значение handleClickRef без потери ссылки на функцию
    //     handleClickRef.current = () => {
    //         navigate("/servicesPage");
    //         updateServiceData();
    //     };

    //     // Функция для установки обработчика
    //     function setClickHandler() {
    //         tg.MainButton.offClick(handleClickRef.current);
    //         tg.MainButton.onClick(handleClickRef.current);
    //     }

    //     setClickHandler();

    //     // Функция очистки
    //     return () => {
    //         tg.MainButton.offClick(handleClickRef.current);
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [serviceName, serviceCategory, servicePrice, serviceDescription, serviceDuration, servicePhotos]);

    return (
        <div>
            {/* <HeaderUniversal>
                {isAddingNewService ? "Добавление услуги" : serviceName === '' ? "Название услуги" : serviceName}
            </HeaderUniversal> */}

            <HeaderUniversal>
                {isAddingNewService ? "Добавление услуги" : initialServiceName}
            </HeaderUniversal>

            <ServiceName
                serviceName={serviceName}
                setServiceName={setServiceName}
            />

            <ServiceCategory
                serviceCategory={serviceCategory}
                setServiceCategory={setServiceCategory}

                categories={categories}
            />

            <ServiceDuration
                serviceDuration={serviceDuration}
                setServiceDuration={setServiceDuration}
            />

            <ServicePrice
                servicePrice={servicePrice}
                setServicePrice={setServicePrice}
            />


            {/* <DualInputDualButton
                serviceName={serviceName}
                setServiceName={setServiceName}

                serviceCategory={serviceCategory}
                setServiceCategory={setServiceCategory}

                serviceDuration={serviceDuration}
                setServiceDuration={setServiceDuration}

                servicePrice={servicePrice}
                setServicePrice={setServicePrice}

                categories={categories}
            /> */}

            <ServiceDescription
                serviceDescription={serviceDescription}
                // placeholder={"Описание"}
                setServiceDescription={setServiceDescription}
            // footnote={"Расскажите, как будет проходить процедура"}
            />

            <ServicePhotosEditor
                servicePhotos={servicePhotos}
                setServicePhotos={setServicePhotos}
                isAddingNewService={isAddingNewService}
            />


            <ServiceDeleteButton
                handleDeleteService={handleDeleteService}
            />


            {/* <InputUniversal
                title={"Услуга"}
                value={serviceName}
                placeholder={"Название услуги"}
                onChange={handleChangeServiceName}
            />

            <InputUniversal
                title={"Категория"}
                value={serviceCategory}
                placeholder={"Например, маникюр"}
                onChange={handleChangeServiceCategory}
            /> */}

            {showBackButtonModal &&
                <ModalUniversal
                    // text={'Изменения в расписании не будут применены. Вы уверены?'}
                    text={isAddingNewService ? 'Услуга не будет сохранена. Вы уверены?' : 'Изменения услуги не буду применены. Вы уверены?'}
                    setIsVisible={setShowBackButtonModal}
                    onConfirm={confirmBackButtonOnClick}
                    confirmText={'Уйти'}
                    cancelText={'Остаться'}
                />
            }

            {showErrorModal &&
                <ModalUniversal
                    text={errorModalText}
                    setIsVisible={setShowErrorModal}
                    cancelText={'Окей'}
                />
            }
        </div>
    )
})

export default AddingOrChangeService