// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServiceDeleteButton_container__JIAbN {
    margin-top: 30px;
    text-align: center;
}

.ServiceDeleteButton_btn__pkLLC {
    background-color: var(--tg-theme-secondary-bg-color);

    border: none;
    border-radius: 10px;
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/addingOrChangeService/serviceDeleteButton/ServiceDeleteButton.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,oDAAoD;;IAEpD,YAAY;IACZ,mBAAmB;IACnB,aAAa;AACjB","sourcesContent":[".container {\n    margin-top: 30px;\n    text-align: center;\n}\n\n.btn {\n    background-color: var(--tg-theme-secondary-bg-color);\n\n    border: none;\n    border-radius: 10px;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ServiceDeleteButton_container__JIAbN`,
	"btn": `ServiceDeleteButton_btn__pkLLC`
};
export default ___CSS_LOADER_EXPORT___;
