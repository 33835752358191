// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduleTemplates_btnContainer__THLjk {
    margin: 0 -15px;
    margin-top: 10px;
}

.ScheduleTemplates_btn__AnRUt {
    
    display: flex;
    align-items: center;
    background-color: var(--tg-theme-bg-color);
    
    height: 44px;
    border: none;
    
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';

    padding-left: 16px;

    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    color: var(--tg-theme-link-color);
}

.ScheduleTemplates_addIcon__cZ3Rm {
    margin-right: 25px;
}`, "",{"version":3,"sources":["webpack://./src/pages/scheduleTemplates/ScheduleTemplates.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;;IAEI,aAAa;IACb,mBAAmB;IACnB,0CAA0C;;IAE1C,YAAY;IACZ,YAAY;;IAEZ,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;;IAE7B,kBAAkB;;IAElB,WAAW;IACX,sBAAsB;IACtB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".btnContainer {\n    margin: 0 -15px;\n    margin-top: 10px;\n}\n\n.btn {\n    \n    display: flex;\n    align-items: center;\n    background-color: var(--tg-theme-bg-color);\n    \n    height: 44px;\n    border: none;\n    \n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n\n    padding-left: 16px;\n\n    width: 100%;\n    box-sizing: border-box;\n    max-width: 100%;\n    color: var(--tg-theme-link-color);\n}\n\n.addIcon {\n    margin-right: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnContainer": `ScheduleTemplates_btnContainer__THLjk`,
	"btn": `ScheduleTemplates_btn__AnRUt`,
	"addIcon": `ScheduleTemplates_addIcon__cZ3Rm`
};
export default ___CSS_LOADER_EXPORT___;
