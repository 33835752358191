// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.Sсhedule_btnContainer__chros {
    margin-top: -17px;
    display: flex;
    justify-content: flex-end;
}

.Sсhedule_btn__Poqkn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 44px;
    color: var(--tg-theme-link-color);
    background-color: var(--tg-theme-secondary-bg-color);
}

`, "",{"version":3,"sources":["webpack://./src/pages/sсhedule/S%D1%81hedule.module.css"],"names":[],"mappings":";;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,iCAAiC;IACjC,oDAAoD;AACxD","sourcesContent":["\n\n.btnContainer {\n    margin-top: -17px;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.btn {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: none;\n    height: 44px;\n    color: var(--tg-theme-link-color);\n    background-color: var(--tg-theme-secondary-bg-color);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnContainer": `Sсhedule_btnContainer__chros`,
	"btn": `Sсhedule_btn__Poqkn`
};
export default ___CSS_LOADER_EXPORT___;
