// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServiceName_container__KnwE2 {
    margin-bottom: 10px;
}

.ServiceName_ttl__GHNX6 {
    color: var(--tg-theme-hint-color);
    margin-left: 16px;
}

.ServiceName_inpt__5eST5 {
    background-color: var(--tg-theme-bg-color);
    /* background-color: aqua; */
    height: 44px;
    border: none;
    color: var(--tg-theme-text-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
    outline: none;
    border-radius: 10px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 7px;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
}

.ServiceName_inpt__5eST5::placeholder {
    color: var(--tg-theme-hint-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
}`, "",{"version":3,"sources":["webpack://./src/components/addingOrChangeService/serviceName/ServiceName.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,iBAAiB;AACrB;;AAEA;IACI,0CAA0C;IAC1C,4BAA4B;IAC5B,YAAY;IACZ,YAAY;IACZ,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;AACjC","sourcesContent":[".container {\n    margin-bottom: 10px;\n}\n\n.ttl {\n    color: var(--tg-theme-hint-color);\n    margin-left: 16px;\n}\n\n.inpt {\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: aqua; */\n    height: 44px;\n    border: none;\n    color: var(--tg-theme-text-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n    outline: none;\n    border-radius: 10px;\n    padding-left: 16px;\n    padding-right: 16px;\n    margin-top: 7px;\n    width: 100%;\n    box-sizing: border-box;\n    max-width: 100%;\n}\n\n.inpt::placeholder {\n    color: var(--tg-theme-hint-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ServiceName_container__KnwE2`,
	"ttl": `ServiceName_ttl__GHNX6`,
	"inpt": `ServiceName_inpt__5eST5`
};
export default ___CSS_LOADER_EXPORT___;
