import { makeObservable, observable, action } from 'mobx';
import axios from 'axios';

import templatesDataFromMyJson from "../Templates.json"

class TemplatesStore {
    templatesData = [];
    templatesData = templatesDataFromMyJson;

    constructor() {
        makeObservable(this, {
            templatesData: observable,
            setTemplatesData: action
        }, { deep: true });
    }

    setTemplatesData = (newData) => {
        this.templatesData = newData;
    }



    updateTemplateById = (id, updatedData) => {
        const index = this.templatesData.findIndex((template) => template.id.toString() === id);
        if (index !== -1) {
            this.templatesData[index] = { ...this.templatesData[index], ...updatedData };
        }
    }

    deleteTemplateById = (id) => {
        this.templatesData = this.templatesData.filter((template) => template.id.toString() !== id);
    }

    addTemplate = (newTemplate) => {
        const newTemplateWithId = { ...newTemplate, id: this.templatesData.length > 0 ? Math.max(...this.templatesData.map(t => t.id)) + 1 : 1 };
        this.templatesData.push(newTemplateWithId);
    }
}

const templateStore = new TemplatesStore();

export default templateStore;
