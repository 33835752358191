// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonUniversal_container__w5sKi {
    margin-bottom: 24px;
}

.ButtonUniversal_btn__bdgw4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--tg-theme-bg-color);
    /* background-color: aqua; */
    height: 44px;
    border: none;
    
    color: var(--tg-theme-hint-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
    border-radius: 10px;
    margin-top: 7px;

    padding-left: 16px;

    padding-right: 17px;

    /* padding: 0 16px; */

    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
}

.ButtonUniversal_ttl__beS9y {
    margin-left: 7px;
}`, "",{"version":3,"sources":["webpack://./src/components/universalComponents/buttonUniversal/ButtonUniversal.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,0CAA0C;IAC1C,4BAA4B;IAC5B,YAAY;IACZ,YAAY;;IAEZ,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;;IAEf,kBAAkB;;IAElB,mBAAmB;;IAEnB,qBAAqB;;IAErB,WAAW;IACX,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".container {\n    margin-bottom: 24px;\n}\n\n.btn {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: aqua; */\n    height: 44px;\n    border: none;\n    \n    color: var(--tg-theme-hint-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n    border-radius: 10px;\n    margin-top: 7px;\n\n    padding-left: 16px;\n\n    padding-right: 17px;\n\n    /* padding: 0 16px; */\n\n    width: 100%;\n    box-sizing: border-box;\n    max-width: 100%;\n}\n\n.ttl {\n    margin-left: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ButtonUniversal_container__w5sKi`,
	"btn": `ButtonUniversal_btn__bdgw4`,
	"ttl": `ButtonUniversal_ttl__beS9y`
};
export default ___CSS_LOADER_EXPORT___;
