import React, { useState } from 'react';
import { HeadlineBody } from "../../../../../textStyles/TextStyleComponents";
import cl from "./TitleForSingleDayOfSchedule.module.css";
import Switch from 'react-ios-switch';
import ModalUniversal from '../../../../universalComponents/modalUniversal/ModalUniversal';


const TitleForSingleDayOfSchedule = ({ stateDay, setDay }) => {
    // Инициализируем состояние для отслеживания статуса переключения
    const tg = window.Telegram.WebApp;
    
    const [switchState, setSwitchState] = useState(stateDay.status === 'WORK');
    
    const [year, month, day] = stateDay.date.split('-').map(num => parseInt(num, 10));
    const dateObj = new Date(year, month - 1, day);

    const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
    const formattedDate = `${dateObj.getDate()} ${months[dateObj.getMonth()]}`;

    const [showSwitchToRestWithBusyCellModal, setShowSwitchToRestWithBusyCellModal] = useState(false);

    // Функция для изменения статуса дня при переключении
    const toggleDayStatus = () => {
        if (switchState && stateDay.recordingCells.some(cell => cell.status === 'BUSY')) {
            setShowSwitchToRestWithBusyCellModal(true);
            return;
        }
        // Меняем состояние свитчера
        const newSwitchState = !switchState;
        setSwitchState(newSwitchState);
    
        // Обновляем статус в объекте stateDay и передаем его в setDay
        const newStatus = newSwitchState ? 'WORK' : 'REST';
        setDay({ ...stateDay, status: newStatus });
    };

    // Определение текста в зависимости от состояния switchState
    let statusText = switchState ? `Работаю ${formattedDate}` : `Отдыхаю ${formattedDate}`;

    return (
        <div className={cl.container}>
            <HeadlineBody>{statusText}</HeadlineBody>
            <div>
                <Switch
                // id={switchId} // Присваиваем свитчеру уникальный id
                    className={`${tg.colorScheme === 'dark' ? cl.customSwitchDark : cl.customSwitchLight} ${switchState ? cl.customSwitchActive : ''}`}
                    checked={switchState}
                    onChange={toggleDayStatus}
                />
            </div>

            {showSwitchToRestWithBusyCellModal &&
                <ModalUniversal
                    text={'На этот день записался клиент. Чтобы сделать день выходным - отмените запись'}
                    setIsVisible={setShowSwitchToRestWithBusyCellModal}
                    cancelText={'Окей'}
                />
            }
        </div>
    );
}

export default TitleForSingleDayOfSchedule;