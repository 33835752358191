import React, { useState, useEffect, useRef } from 'react';
import { HeadlineBody } from '../../../textStyles/TextStyleComponents';
import cl from './TimePickerForSchedule.module.css';


const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
const minutes = ['00', '15', '30', '45'];

const TimePickerForSchedule = ({ onTimeSelect, initialTime, onClose }) => {
    const [selectedHour, setSelectedHour] = useState(initialTime.split(':')[0]);
    const [selectedMinute, setSelectedMinute] = useState(initialTime.split(':')[1]);

    const pickerRef = useRef(null);
    const hourScrollRef = useRef(null);
    const minuteScrollRef = useRef(null);
    const scrollTimeoutRef = useRef(null);

    const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (hourScrollRef.current) {
            hourScrollRef.current.scrollTop = hours.indexOf(selectedHour) * 35;
        }
        if (minuteScrollRef.current) {
            minuteScrollRef.current.scrollTop = minutes.indexOf(selectedMinute) * 35;
        }
    }, [selectedHour, selectedMinute]);

    const smoothScrollTo = (scrollRef, targetPosition) => {
        const startPosition = scrollRef.current.scrollTop;
        const distance = targetPosition - startPosition;
        const duration = 300; // Продолжительность анимации в миллисекундах
        let startTime = null;

        const animation = (currentTime) => {
            if (!startTime) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const progress = Math.min(timeElapsed / duration, 1);

            scrollRef.current.scrollTop = startPosition + distance * easeInOutQuad(progress);

            if (timeElapsed < duration) {
                requestAnimationFrame(animation);
            }
        };

        requestAnimationFrame(animation);
    };

    const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;

    const handleScrollStop = (scrollRef, items, setSelectedValue) => {
        const index = Math.round(scrollRef.current.scrollTop / 35);
        const targetPosition = index * 35;
        smoothScrollTo(scrollRef, targetPosition);
        setSelectedValue(items[index]);
        onTimeSelect(`${selectedHour}:${selectedMinute}`);
    };

    const handleScroll = (scrollRef, items, setSelectedValue) => {
        if (scrollTimeoutRef.current) {
            clearTimeout(scrollTimeoutRef.current);
        }
        scrollTimeoutRef.current = setTimeout(() => handleScrollStop(scrollRef, items, setSelectedValue), 100);
    };

    return (
        <div className={cl.container} ref={pickerRef}>
            <div className={cl.scrollContainer}>
                <div
                    className={cl.scroll}
                    ref={hourScrollRef}
                    onScroll={() => handleScroll(hourScrollRef, hours, setSelectedHour)}
                >
                    {hours.map(hour => (
                        <div
                            key={hour}
                            className={cl.timeItem}
                        >
                            <HeadlineBody>{hour}</HeadlineBody>
                        </div>
                    ))}
                </div>
                <div className={cl.selectedContainer}></div>
                <div
                    className={cl.scroll}
                    ref={minuteScrollRef}
                    onScroll={() => handleScroll(minuteScrollRef, minutes, setSelectedMinute)}
                >
                    {minutes.map(minute => (
                        <div
                            key={minute}
                            className={cl.timeItem}
                        >
                            <HeadlineBody>{minute}</HeadlineBody>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};


export default TimePickerForSchedule;
