// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputUniversal_container__mFiRr {
    margin-bottom: 24px;
}

.InputUniversal_containerWithFootnote__UsD24 {
    margin-bottom: 14px;
}

.InputUniversal_inpt__\\+GHcA {
    background-color: var(--tg-theme-bg-color);
    /* background-color: aqua; */
    height: 44px;
    border: none;
    color: var(--tg-theme-text-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
    outline: none;
    border-radius: 10px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 7px;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
}

.InputUniversal_inpt__\\+GHcA::placeholder {
    color: var(--tg-theme-hint-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
}

.InputUniversal_ttl__m6AXw {
    margin-left: 7px;
}

.InputUniversal_ftnt__pfHSs {
    padding-left: 16px;
    padding-top: 4px;
    color: var(--tg-theme-hint-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/universalComponents/inputUniversal/InputUniversal.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,0CAA0C;IAC1C,4BAA4B;IAC5B,YAAY;IACZ,YAAY;IACZ,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,iCAAiC;AACrC","sourcesContent":[".container {\n    margin-bottom: 24px;\n}\n\n.containerWithFootnote {\n    margin-bottom: 14px;\n}\n\n.inpt {\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: aqua; */\n    height: 44px;\n    border: none;\n    color: var(--tg-theme-text-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n    outline: none;\n    border-radius: 10px;\n    padding-left: 16px;\n    padding-right: 16px;\n    margin-top: 7px;\n    width: 100%;\n    box-sizing: border-box;\n    max-width: 100%;\n}\n\n.inpt::placeholder {\n    color: var(--tg-theme-hint-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n}\n\n.ttl {\n    margin-left: 7px;\n}\n\n.ftnt {\n    padding-left: 16px;\n    padding-top: 4px;\n    color: var(--tg-theme-hint-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `InputUniversal_container__mFiRr`,
	"containerWithFootnote": `InputUniversal_containerWithFootnote__UsD24`,
	"inpt": `InputUniversal_inpt__+GHcA`,
	"ttl": `InputUniversal_ttl__m6AXw`,
	"ftnt": `InputUniversal_ftnt__pfHSs`
};
export default ___CSS_LOADER_EXPORT___;
