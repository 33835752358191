import cl from "./ScheduleTemplates.module.css"
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import HeaderUniversal from "../../components/universalComponents/headerUniversal/HeaderUniversal";
import ListOfTemplatesForSchedule from "../../components/sсhedule/templatesForSchedule/listOfTemplatesForSchedule/ListOfTemplatesForSchedule";
import { ReactComponent as AddNewTemplate } from "../../icons/AddNewTemplateIcon.svg";
import { HeadlineBody } from "../../textStyles/TextStyleComponents";
import templateStore from "../../store/TemplatesStore";
import axios from "axios";
import firstTwoPageStore from "../../store/FirstTwoPageStore";

const ScheduleTemplates = () => {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate()

    useEffect(() => {
        if (!firstTwoPageStore.hasFetchedSelfEmployedData) {
            navigate('*')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getTemplatesData = async () => {
            const botUsername = localStorage.getItem('botUsername')
            try {
                const response = await axios.get(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/schedule`);
                templateStore.templatesData = (response.data.templates);
                console.log("Загружены данны о шаблонах", response);
            } catch (error) {
                console.error('Ошибка загрузки данных о шаблонах', error);
                const errorCode = error.response?.status || 500;
                const errorMessage = error.response?.statusText || 'Internal Server Error';
                navigate('/errorPage', { state: { errorCode, errorMessage } });
            }
        }

        getTemplatesData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        tg.MainButton.enable();


        tg.MainButton.setText("Готово");

        const handleMainButtonClick = () => {
            navigate("/sсhedule")
        }

        const handleBackButtonOnClick = () => {
            navigate("/sсhedule")
        }

        tg.MainButton.onClick(handleMainButtonClick)
        tg.BackButton.onClick(handleBackButtonOnClick)

        return () => {
            tg.MainButton.offClick(handleMainButtonClick);
            tg.BackButton.offClick(handleBackButtonOnClick);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAddNewTemplate = () => {
        // Переход на страницу создания нового шаблона с временным id, например, "new"
        navigate("/template/new");
    }

    return (
        <div>
            <HeaderUniversal>Шаблоны</HeaderUniversal>
            <ListOfTemplatesForSchedule />

            <div className={cl.btnContainer}>
                <button className={cl.btn} onClick={onAddNewTemplate}>
                    <AddNewTemplate className={cl.addIcon} />
                    <HeadlineBody>Добавить шаблон</HeadlineBody>
                </button>
            </div>

        </div>
    )
};

export default ScheduleTemplates