// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicesButton_container__Va14Z {
    margin: 0 -15px;
    overflow: hidden;
}

.ServicesButton_btn__gwboO {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 44px;
    background-color: var(--tg-theme-bg-color);
    
    border: none;
    width: 100%;

    color: var(--tg-theme-text-color);

    padding-left: 15px;
    padding-right: 16px;
}

.ServicesButton_btnText__ACaFL {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
}

`, "",{"version":3,"sources":["webpack://./src/components/servicesPage/servicesButton/ServicesButton.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;;IAEnB,YAAY;IACZ,0CAA0C;;IAE1C,YAAY;IACZ,WAAW;;IAEX,iCAAiC;;IAEjC,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,cAAc;AAClB","sourcesContent":[".container {\n    margin: 0 -15px;\n    overflow: hidden;\n}\n\n.btn {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    height: 44px;\n    background-color: var(--tg-theme-bg-color);\n    \n    border: none;\n    width: 100%;\n\n    color: var(--tg-theme-text-color);\n\n    padding-left: 15px;\n    padding-right: 16px;\n}\n\n.btnText {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    max-width: 95%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ServicesButton_container__Va14Z`,
	"btn": `ServicesButton_btn__gwboO`,
	"btnText": `ServicesButton_btnText__ACaFL`
};
export default ___CSS_LOADER_EXPORT___;
