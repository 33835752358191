// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimeInput_container__31GAH {
    display: flex;
    justify-content: space-between;
}

.TimeInput_inpt__RHnww {
    position: relative;
    background-color: var(--tg-theme-bg-color);
    height: 44px;
    width: 50%;
    border: none;
    color: var(--tg-theme-text-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
    outline: none;
    padding-left: 16px;
    padding-right: 36px
}

.TimeInput_inpt__RHnww::placeholder {
    color: var(--tg-theme-hint-color);    
}

.TimeInput_separator__pG7G2 {
    border-left: 1px solid var(--tg-theme-secondary-bg-color);
    margin-top: 13.5px;
    margin-bottom: 13.5px;
    height: calc(100% - 27px);
}`, "",{"version":3,"sources":["webpack://./src/components/addingOrChangeService/dualInputDualButton/timeInput/TimeInput.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,0CAA0C;IAC1C,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;IAC7B,aAAa;IACb,kBAAkB;IAClB;AACJ;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,yDAAyD;IACzD,kBAAkB;IAClB,qBAAqB;IACrB,yBAAyB;AAC7B","sourcesContent":[".container {\n    display: flex;\n    justify-content: space-between;\n}\n\n.inpt {\n    position: relative;\n    background-color: var(--tg-theme-bg-color);\n    height: 44px;\n    width: 50%;\n    border: none;\n    color: var(--tg-theme-text-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n    outline: none;\n    padding-left: 16px;\n    padding-right: 36px\n}\n\n.inpt::placeholder {\n    color: var(--tg-theme-hint-color);    \n}\n\n.separator {\n    border-left: 1px solid var(--tg-theme-secondary-bg-color);\n    margin-top: 13.5px;\n    margin-bottom: 13.5px;\n    height: calc(100% - 27px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TimeInput_container__31GAH`,
	"inpt": `TimeInput_inpt__RHnww`,
	"separator": `TimeInput_separator__pG7G2`
};
export default ___CSS_LOADER_EXPORT___;
