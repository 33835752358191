import { useEffect, useState, useRef } from "react"
import cl from "./TimeInput.module.css"
import { useNavigate } from "react-router-dom"
import validationStore from "../../../../store/ValidationStore";

const TimeInput = ({ serviceDuration, setServiceDuration, serviceName, servicePrice, serviceCategory }) => {

    const tg = window.Telegram.WebApp;
    const navigate = useNavigate()

    const [serviceHours, setServiceHours] = useState(
        Math.floor(serviceDuration / 60) > 0 ? Math.floor(serviceDuration / 60) + " ч" : ""
    );
    const [serviceMinutes, setServiceMinutes] = useState(
        serviceDuration % 60 > 0 ? serviceDuration % 60 + " мин" : ""
    );

    const handleChangeServiceHours = (e) => {
        const value = e.target.value;
        let numericValue = value.replace(/[^0-9.]/g, '');

        if (numericValue.startsWith('0')) {
            numericValue = numericValue.slice(1);
        }

        if (numericValue > validationStore.maxHoursServiceDurationInputValue) {
            setTimeout(() => {
                inputRefHours.current.setSelectionRange(serviceHours.length - 2, serviceHours.length - 2);
            }, 0);
            return;
        }

        // Сохраняем текущую позицию курсора
        const cursorPosition = e.target.selectionStart;

        // Обновляем состояние
        setServiceHours(numericValue ? numericValue + " ч" : '');

        // Проверяем, что состояние уже обновлено и элемент ввода сфокусирован
        setTimeout(() => {
            if (inputRefHours.current && numericValue) {
                inputRefHours.current.setSelectionRange(cursorPosition, cursorPosition);
            }
        }, 0);
    }

    const handleChangeServiceMinutes = (e) => {
        const value = e.target.value;
        let numericValue = value.replace(/[^0-9.]/g, '');

        if (numericValue.startsWith('0')) {
            numericValue = numericValue.slice(1);
        }

        if (numericValue > validationStore.maxMinutesServiceDurationInputValue) {
            setTimeout(() => {
                inputRefMinutes.current.setSelectionRange(serviceMinutes.length - 4, serviceMinutes.length - 4);
            }, 0);
            return;
        }

        // Сохраняем текущую позицию курсора
        const cursorPosition = e.target.selectionStart;

        // Обновляем состояние
        setServiceMinutes(numericValue ? numericValue + " мин" : '');

        // Проверяем, что состояние уже обновлено и элемент ввода сфокусирован
        setTimeout(() => {
            if (inputRefMinutes.current && numericValue) {
                inputRefMinutes.current.setSelectionRange(cursorPosition, cursorPosition);
            }
        }, 0);
    }

    useEffect(() => {
        const hours = serviceHours.endsWith(' ч') ? Number(serviceHours.slice(0, -2)) : Number(serviceHours);
        const minutes = serviceMinutes.endsWith(' мин') ? Number(serviceMinutes.slice(0, -4)) : Number(serviceMinutes);
        setServiceDuration(hours * 60 + minutes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceHours, serviceMinutes]);


    const inputRefHours = useRef(null);

    const handleKeyDownHours = (e) => {
        const { selectionStart, selectionEnd } = e.target;

        // Запретить перемещение курсора за пределы разрешенного диапазона с клавиатуры
        if ((selectionStart >= serviceHours.length - 2 && selectionEnd >= serviceHours.length - 2) &&
            (e.key === "ArrowRight" || e.key === "End")) {
            e.preventDefault();
        }
        if ((selectionStart <= serviceHours.length - 2 && selectionEnd <= serviceHours.length - 2) &&
            (e.key === "ArrowLeft" || e.key === "Home")) {
            e.preventDefault();
        }
    };

    const handleClickHours = (e) => {
        // Предотвратить установку курсора за последними двумя символами при клике мышью
        const { selectionStart, selectionEnd } = inputRefHours.current;

        if (selectionStart > serviceHours.length - 2 || selectionEnd > serviceHours.length - 2) {
            inputRefHours.current.setSelectionRange(serviceHours.length - 2, serviceHours.length - 2);
            e.preventDefault(); // Предотвратить дальнейшее событие клика
        }
    };


    const inputRefMinutes = useRef(null);

    const handleKeyDownMinutes = (e) => {
        const { selectionStart, selectionEnd } = e.target;

        // Запретить перемещение курсора за пределы разрешенного диапазона с клавиатуры
        if ((selectionStart >= serviceMinutes.length - 4 && selectionEnd >= serviceMinutes.length - 4) &&
            (e.key === "ArrowRight" || e.key === "End")) {
            e.preventDefault();
        }
        if ((selectionStart <= serviceMinutes.length - 4 && selectionEnd <= serviceMinutes.length - 4) &&
            (e.key === "ArrowLeft" || e.key === "Home")) {
            e.preventDefault();
        }
    };

    const handleClickMinutes = (e) => {
        // Предотвратить установку курсора за последними двумя символами при клике мышью
        const { selectionStart, selectionEnd } = inputRefMinutes.current;

        if (selectionStart > serviceMinutes.length - 4 || selectionEnd > serviceMinutes.length - 4) {
            inputRefMinutes.current.setSelectionRange(serviceMinutes.length - 4, serviceMinutes.length - 4);
            e.preventDefault(); // Предотвратить дальнейшее событие клика
        }
    };

    useEffect(() => {
        tg.MainButton.disable();

        if (tg.colorScheme === "light") {
            tg.MainButton.color = "#e8e8e9";
            tg.MainButton.textColor = "#b9b9ba";
        } else {
            tg.MainButton.color = "#2f2f2f";
            tg.MainButton.textColor = "#606060";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (serviceName && (serviceHours || serviceMinutes) && servicePrice && serviceCategory) {
            if (!tg.MainButton.isActive) {
                tg.MainButton.enable()
            }
            if (tg.MainButton.color !== tg.themeParams.button_color) {
                tg.MainButton.color = tg.themeParams.button_color
                tg.MainButton.textColor = tg.themeParams.button_text_color
            }
        } else {
            tg.MainButton.disable();
            if (tg.colorScheme === "light") {
                tg.MainButton.color = "#e8e8e9";
                tg.MainButton.textColor = "#b9b9ba";
            } else {
                tg.MainButton.color = "#2f2f2f";
                tg.MainButton.textColor = "#606060";
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceName, servicePrice, serviceHours, serviceMinutes, serviceCategory])

    return (
        <div className={cl.container}>
            <input
                inputMode="numeric"
                className={`${cl.inpt} ${cl.inptHours}`}
                placeholder={"Часы"}
                value={serviceHours}
                onChange={handleChangeServiceHours}

                ref={inputRefHours}
                onKeyDown={handleKeyDownHours}
                onClick={handleClickHours}
                onMouseUp={handleClickHours}
            />

            <div className={cl.separator}></div>

            <input
                inputMode="numeric"
                className={`${cl.inpt} ${cl.inptMinutes}`}
                placeholder={"Минуты"}
                value={serviceMinutes}
                onChange={handleChangeServiceMinutes}

                ref={inputRefMinutes}
                onKeyDown={handleKeyDownMinutes}
                onClick={handleClickMinutes}
                onMouseUp={handleClickMinutes}
            />
        </div>
    )
}

export default TimeInput