// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonOfTemplatesForSchedule_container__OpTHY {
    margin: 0 -15px;
}

.ButtonOfTemplatesForSchedule_btn__hubxR {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    height: 44px;
    background-color: var(--tg-theme-bg-color);
    
    
    border: none;
    width: 100%;

    color: var(--tg-theme-text-color);

    padding-left: 15px;
    padding-right: 16px;
}

.ButtonOfTemplatesForSchedule_btnText__tH3aK {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
}

.ButtonOfTemplatesForSchedule_borderBottom__MEZiS {
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 0;
    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
    pointer-events: none;
} `, "",{"version":3,"sources":["webpack://./src/components/sсhedule/templatesForSchedule/listOfTemplatesForSchedule/buttonOfTemplatesForSchedule/ButtonOfTemplatesForSchedule.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;;IAElB,YAAY;IACZ,0CAA0C;;;IAG1C,YAAY;IACZ,WAAW;;IAEX,iCAAiC;;IAEjC,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,QAAQ;IACR,2DAA2D;IAC3D,oBAAoB;AACxB","sourcesContent":[".container {\n    margin: 0 -15px;\n}\n\n.btn {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    position: relative;\n\n    height: 44px;\n    background-color: var(--tg-theme-bg-color);\n    \n    \n    border: none;\n    width: 100%;\n\n    color: var(--tg-theme-text-color);\n\n    padding-left: 15px;\n    padding-right: 16px;\n}\n\n.btnText {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    max-width: 95%;\n}\n\n.borderBottom {\n    position: absolute;\n    bottom: 0;\n    left: 16px;\n    right: 0;\n    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);\n    pointer-events: none;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ButtonOfTemplatesForSchedule_container__OpTHY`,
	"btn": `ButtonOfTemplatesForSchedule_btn__hubxR`,
	"btnText": `ButtonOfTemplatesForSchedule_btnText__tH3aK`,
	"borderBottom": `ButtonOfTemplatesForSchedule_borderBottom__MEZiS`
};
export default ___CSS_LOADER_EXPORT___;
