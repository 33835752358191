import { HeadlineBody } from "../../../../../textStyles/TextStyleComponents"
import cl from "./SelectTemplate.module.css"
import { useState, useRef, useEffect } from "react"
import { ReactComponent as TemplateSelectIcon } from '../../../../../icons/TemplateSelect.svg'
import templateStore from "../../../../../store/TemplatesStore"
import { observer } from "mobx-react-lite"

const SelectTemplate = observer(({ selectedTemplateId, setSelectedTemplateId, setGaps }) => {
    const tg = window.Telegram.WebApp;
    // const [selectedTamplate, setSelectedTemplate] = useState("Обычный день");
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const dropdownItemStyleClass = tg.colorScheme === 'dark' ? cl.darkDropdownItem : cl.lightDropdownItem;

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible)
    }

    const handleTemplateSelect = (template) => {
        setSelectedTemplateId(template.id);
        setGaps(template.gaps)
        setDropdownVisible(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.closest(`.${cl.selectContainer}`)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        if (selectedTemplateId) {
            setGaps(templateStore.templatesData.find(template => template.id === selectedTemplateId)?.gaps)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    return (
        <div className={cl.container}>
            <div className={cl.selectContainer} onClick={toggleDropdown}>
                <HeadlineBody>Выбрать шаблон</HeadlineBody>

                <div className={cl.nameTemplateAndIconContainer}>
                    <HeadlineBody className={selectedTemplateId ? cl.linkText : cl.hintText}>
                        {selectedTemplateId ? templateStore.templatesData.find(template => template.id === selectedTemplateId)?.name : 'Без шаблона'}
                    </HeadlineBody>
                    <TemplateSelectIcon className={cl.icon} />
                </div>
            </div>
            {isDropdownVisible && (
                //<div className={`${cl.dropdown} ${tg.colorScheme === 'dark' ? cl.darkBorder : ''}`}>
                <div ref={dropdownRef} className={`${cl.dropdownContainer} ${tg.colorScheme === 'dark' ? cl.darkBorder : cl.lightBorder}`} >
                    {templateStore.templatesData.map((template, index) => {
                        const isSelected = template.id === selectedTemplateId;

                        // Определяем, какой элемент является первым, последним или единственным
                        const isFirst = index === 0;
                        const isLast = index === templateStore.templatesData.length - 1;

                        // Формируем класс для элемента
                        const itemClassName = `${cl.dropdownItem} ${dropdownItemStyleClass} ${isSelected ? cl.selectedDropdownItem : ''} 
                          ${isFirst ? cl.firstDropdownItem : ''} 
                          ${isLast ? cl.lastDropdownItem : ''}`;

                        return (
                            <div
                                key={template.id}
                                className={itemClassName}
                                onClick={() => handleTemplateSelect(template)}
                            >
                                <HeadlineBody>{template.name}</HeadlineBody>
                                {index !== templateStore.templatesData.length - 1 && <div className={cl.borderBottom}></div>}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    )
})

export default SelectTemplate