// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddServiceButton_container__7V3CT {
    margin: 0 -15px;
    margin-bottom: 40px;
}

.AddServiceButton_btn__wi9wD {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--tg-theme-bg-color);
    height: 46px;
    border: none;


    padding-left: 16px;

    width: 100%;
    /* box-sizing: border-box;
    max-width: 100%; */
}`, "",{"version":3,"sources":["webpack://./src/components/servicesPage/addServiceButton/AddServiceButton.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,0CAA0C;IAC1C,YAAY;IACZ,YAAY;;;IAGZ,kBAAkB;;IAElB,WAAW;IACX;sBACkB;AACtB","sourcesContent":[".container {\n    margin: 0 -15px;\n    margin-bottom: 40px;\n}\n\n.btn {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: var(--tg-theme-bg-color);\n    height: 46px;\n    border: none;\n\n\n    padding-left: 16px;\n\n    width: 100%;\n    /* box-sizing: border-box;\n    max-width: 100%; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AddServiceButton_container__7V3CT`,
	"btn": `AddServiceButton_btn__wi9wD`
};
export default ___CSS_LOADER_EXPORT___;
