// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Duration_container__5VU-- {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Duration_btn__SLoOB {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--tg-theme-bg-color);
    /* background-color: aqua; */
    
    height: 44px;
    border: none;

    /* font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular'; */

    padding-left: 16px;

    padding-right: 16px;

    /* padding: 0 16px; */

    
    box-sizing: border-box;
    max-width: 100%;
}

.Duration_borderBottom__Q7mYr {
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 0;
    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
    
    pointer-events: none;
}

.Duration_durationInscription__9FZs8 {
    color: var(--tg-theme-link-color);
}

.Duration_durationWord__dqBQf {
    color: var(--tg-theme-text-color)
}`, "",{"version":3,"sources":["webpack://./src/components/addingOrChangeService/dualInputDualButton/duration/Duration.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,0CAA0C;IAC1C,4BAA4B;;IAE5B,YAAY;IACZ,YAAY;;IAEZ;;;oCAGgC;;IAEhC,kBAAkB;;IAElB,mBAAmB;;IAEnB,qBAAqB;;;IAGrB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,QAAQ;IACR,2DAA2D;;IAE3D,oBAAoB;AACxB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI;AACJ","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.btn {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: aqua; */\n    \n    height: 44px;\n    border: none;\n\n    /* font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular'; */\n\n    padding-left: 16px;\n\n    padding-right: 16px;\n\n    /* padding: 0 16px; */\n\n    \n    box-sizing: border-box;\n    max-width: 100%;\n}\n\n.borderBottom {\n    position: absolute;\n    bottom: 0;\n    left: 16px;\n    right: 0;\n    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);\n    \n    pointer-events: none;\n}\n\n.durationInscription {\n    color: var(--tg-theme-link-color);\n}\n\n.durationWord {\n    color: var(--tg-theme-text-color)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Duration_container__5VU--`,
	"btn": `Duration_btn__SLoOB`,
	"borderBottom": `Duration_borderBottom__Q7mYr`,
	"durationInscription": `Duration_durationInscription__9FZs8`,
	"durationWord": `Duration_durationWord__dqBQf`
};
export default ___CSS_LOADER_EXPORT___;
