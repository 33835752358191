import React, { useEffect, useState } from "react";
import HeaderUniversal from "../../components/universalComponents/headerUniversal/HeaderUniversal";
import cl from "./Instructions.module.css"
import { Footnote, HeadlineBody, HeadLineSemibold } from "../../textStyles/TextStyleComponents";
import InputUniversal from "../../components/universalComponents/inputUniversal/InputUniversal";
import firstTwoPageStore from "../../store/FirstTwoPageStore";
import { observer } from "mobx-react-lite";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import instruction1 from '../../screenshots/1instruction.png'
import instruction2 from '../../screenshots/2instruction.png'
import instruction3 from '../../screenshots/3instruction.png'
import instruction4 from '../../screenshots/4instruction.png'
import instruction5 from '../../screenshots/5instruction.png'
import ModalUniversal from "../../components/universalComponents/modalUniversal/ModalUniversal";


const Instructions = observer(() => {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate()

    const botUsername = localStorage.getItem('botUsername');

    const [showValidationModal, setShowValidationModal] = useState(false);
    const [validationModalText, setValidationModalText] = useState('');

    const [showErrorModal, setShowErrorModal] = useState(false);

    // const fetchData = async () => {
    //     const initData = window.Telegram.WebApp.initData;

    //     // Функция для парсинга initData
    //     const parseInitData = (initData) => {
    //         return Object.fromEntries(new URLSearchParams(initData));
    //     };

    //     const parsedData = parseInitData(initData);

    //     // Формирование stringData
    //     // const dataCheckString = `auth_date=${parsedData.auth_date}\nfirst_name=${parsedData.user.first_name}\nid=${parsedData.user.id}\nusername=${parsedData.user.username}`;

    //     const dataCheckString = `auth_date=${parsedData.auth_date}\nquery_id=${parsedData.query_id}\nuser=${parsedData.user}`;

    //     const hash = parsedData.hash;

    //     console.log("ДАТА ЧЕК СТРИНГ:", dataCheckString);
    //     console.log('ХЭШ:', hash);

    //     const telegramInitData = tg.initData;

    //     try {
    //         const response = await axios.get('${process.env.REACT_APP_MASTER_BOT_URL}manage_bot_api/verify', {
    //             params: {
    //                 telegramInitData
    //                 // dataCheckString,
    //                 // hash
    //             }
    //         });
    //         console.log('Response data:', response.data);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    const cleanUsername = (username) => {
        if (username.startsWith('@')) {
            return username.slice(1); // Удаляет первый символ, если это '@'
        }
        return username;
    };



    useEffect(() => {
        tg.MainButton.setText("Готово")
        tg.MainButton.color = "#34C759"
        tg.MainButton.textColor = "#FFFFFF"

        const handleMainButtonOnClick = async () => {
            tg.MainButton.disable();
            const requestData = {
                userId: botUsername,
                botUsername: cleanUsername(firstTwoPageStore.username.trim()),
                botApiToken: firstTwoPageStore.token.trim()
            }

            if (requestData.botUsername < 5) {
                setValidationModalText('Юзернейм не может быть меньше 5 символов');
                setShowValidationModal(true);
                return;
            }
            if (requestData.botUsername > 32) {
                setValidationModalText('Юзернейм не может быть больше 32 символов');
                setShowValidationModal(true);
                return;
            }
            if (!requestData.botUsername.toLowerCase().endsWith('bot')) {
                setValidationModalText('Юзернейм должен заканчиваться на "bot"');
                setShowValidationModal(true);
                return;
            }

            try {
                const response = await axios.post(`${process.env.REACT_APP_MAIN_API_URL}self-employed`, requestData);
                console.log('Ответ от сервера:', response.data);
                firstTwoPageStore.isBotRegistration = false;
                navigate('*');

                try {
                    const response = await axios.post(`${process.env.REACT_APP_MASTER_BOT_URL}manage_bot_api/notifier/${botUsername}/show-menu`);
                    console.log('Ответ от Ромы:', response.data);
                } catch (error) {
                    if (error.response) {
                        // Сервер вернул ответ с кодом ошибки, который находится в пределах диапазона 2xx
                        const errorData = error.response.data;
                        console.error("Ошибка Ромы:", errorData.error);
                        console.error("Описание Ромы:", errorData.description);
                    } else if (error.request) {
                        // Запрос был сделан, но ответа не было получено
                        console.error("Запрос Роме был сделан, но ответа не было получено", error.request);
                    } else {
                        // Произошла другая ошибка при настройке запроса
                        console.error("Ошибка Ромы:", error.message);
                    }
                }

            } catch (error) {
                setShowErrorModal(true);
                tg.MainButton.enable();
                if (error.response) {
                    // Сервер вернул ответ с кодом ошибки, который находится в пределах диапазона 2xx
                    const errorData = error.response.data;
                    console.error("Ошибка:", errorData.error);
                    console.error("Описание:", errorData.description);
                } else if (error.request) {
                    // Запрос был сделан, но ответа не было получено
                    console.error("Запрос был сделан, но ответа не было получено", error.request);
                } else {
                    // Произошла другая ошибка при настройке запроса
                    console.error("Ошибка:", error.message);
                }
            }
        }

        const handleBackButtonOnClick = () => {
            navigate('*');
        }

        tg.MainButton.onClick(handleMainButtonOnClick);
        tg.BackButton.onClick(handleBackButtonOnClick);

        return () => {
            tg.MainButton.offClick(handleMainButtonOnClick);
            tg.BackButton.offClick(handleBackButtonOnClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (firstTwoPageStore.token && firstTwoPageStore.username) {
            if (!tg.MainButton.isVisible) tg.MainButton.show();
        } else {
            if (tg.MainButton.isVisible) tg.MainButton.hide();
        }


        // if (firstTwoPageStore.token || firstTwoPageStore.username) {
        //     if (!tg.isClosingConfirmationEnabled) tg.enableClosingConfirmation();
        //     if (tg.isClosingConfirmationEnabled) tg.disableClosingConfirmation();
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstTwoPageStore.token, firstTwoPageStore.username]);

    console.log(process.env.REACT_APP_MAIN_API_URL)

    console.log(process.env.REACT_APP_MASTER_BOT_URL)

    return (
        <div>
            <HeaderUniversal>Сделайте себе бота</HeaderUniversal>

            <HeadlineBody style={{ marginBottom: '30px' }}>
                Это будет твой личный бот, через которого будут записываться клиенты. После того, как ты создашь бота, кидай его клиентам и наслаждайся сервисом.
            </HeadlineBody>

            <HeadLineSemibold style={{ marginBottom: '8px' }}>Пошаговая инструкция</HeadLineSemibold>

            <HeadlineBody style={{ marginBottom: '20px' }}>
                У всех ботов в Telegram есть папа - BotFather, это официальный бот Telegram с галочкой в названии. Именно в этом боте тебе нужно создавать своего.
            </HeadlineBody>

            <HeadlineBody style={{ marginBottom: '12px' }}>
                1) Зайди в бота <a href={"https://t.me/BotFather"} className={cl.link}>@BotFather</a> и нажми ”СТАРТ” (“START”)
            </HeadlineBody>

            <div className={cl.center}>
                <img src={instruction1} alt={"instruction1"} />
            </div>

            <Footnote className={cl.center} style={{ marginBottom: '20px' }}>Это официальный бот Telegram, у него есть галочка</Footnote>

            <HeadlineBody style={{ marginBottom: '12px' }}>
                2) Отправь сообщение /newbot
            </HeadlineBody>

            <div className={cl.center} style={{ marginBottom: '20px' }}>
                <img src={instruction2} alt={"instruction2"} />
            </div>

            <HeadlineBody style={{ marginBottom: '12px' }}>
                3) Введи имя бота, клиенты будут его видеть. Его можно менять
            </HeadlineBody>

            <div className={cl.center} style={{ marginBottom: '20px' }}>
                <img src={instruction3} alt={"instruction3"} />
            </div>

            <HeadlineBody style={{ marginBottom: '12px' }}>
                4) Введи юзернейм бота — по нему можно будет найти бота в Telegram. Юзернейм должен быть уникальным и заканчиваться на слово bot. Юзернейм <b>нельзя</b> поменять после этого!
            </HeadlineBody>

            <div className={cl.center} style={{ marginBottom: '20px' }}>
                <img src={instruction4} alt={"instruction4"} />
            </div>

            <div className={cl.usernameContainer}>
                <InputUniversal
                    title={"Username бота"}
                    value={firstTwoPageStore.username}
                    placeholder={"Заканчивается на bot"}
                    setValue={firstTwoPageStore.setUsername}
                    footnote={'Скопируйте юзернейм своего бота сюда без @'}
                />
            </div>

            <HeadlineBody style={{ marginBottom: '12px' }}>
                5) В последнем сообщении ты увидишь HTTP API токен бота, он нам и нужен
            </HeadlineBody>

            <div className={cl.center}>
                <img src={instruction5} alt={"instruction5"} />
            </div>

            <Footnote className={cl.center} style={{ marginBottom: '12px' }}>
                Токен выделен желтым
            </Footnote>


            <HeadlineBody style={{ marginBottom: '12px' }}>
                Скопируй его и вставь в поле ниже
            </HeadlineBody>



            <div className={cl.tokenContainer}>
                <InputUniversal
                    title={"HTTP API Токен"}
                    value={firstTwoPageStore.token}
                    placeholder={"Много букв и цифр"}
                    setValue={firstTwoPageStore.setToken}
                />
            </div>

            <HeadlineBody style={{ marginBottom: '12px' }}>
                Важно! Никому не передавайте этот Токен: злоумышленники смогут управлять вашим ботом ради своей выгоды. Но нам токен необходим, чтобы подключить вашего бота к системе, без этого никак. Мы не передаем токен третьим лицам и храним в надежном месте.
            </HeadlineBody>

            <HeadlineBody style={{ marginBottom: '40px' }}>
                По любым вопросам пишите в поддержку: <a href={"https://t.me/GrosServiceSupport"} className={cl.link}>@GrosServiceSupport</a>
            </HeadlineBody>



            {showValidationModal &&
                <ModalUniversal
                    text={validationModalText}
                    setIsVisible={setShowValidationModal}
                    cancelText={'Окей'}
                />
            }

            {showErrorModal &&
                <ModalUniversal
                    text={'Не удалось загрузить сервис в бота. Попробуйте снова'}
                    setIsVisible={setShowErrorModal}
                    cancelText={'Окей'}
                />
            }
        </div>
    )
})

export default Instructions