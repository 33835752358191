// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicesBlocks_blockName__peJ94 {
    color: var(--tg-theme-hint-color);
    padding-bottom: 3px;
}

.ServicesBlocks_servicesButtonContainer__3Z-V- {
    margin-bottom: 20px;
}

.ServicesBlocks_serviceItemContainer__L3BU- {
    position: relative; 
}

.ServicesBlocks_borderBottom__Csrgx {
    position: absolute;
    bottom: 0;
    left: 0; 
    right: -15px;
    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
}

.ServicesBlocks_errorMessage__MIsqV {
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/servicesPage/servicesBlocks/ServicesBlocks.module.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,YAAY;IACZ,2DAA2D;AAC/D;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".blockName {\n    color: var(--tg-theme-hint-color);\n    padding-bottom: 3px;\n}\n\n.servicesButtonContainer {\n    margin-bottom: 20px;\n}\n\n.serviceItemContainer {\n    position: relative; \n}\n\n.borderBottom {\n    position: absolute;\n    bottom: 0;\n    left: 0; \n    right: -15px;\n    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);\n}\n\n.errorMessage {\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockName": `ServicesBlocks_blockName__peJ94`,
	"servicesButtonContainer": `ServicesBlocks_servicesButtonContainer__3Z-V-`,
	"serviceItemContainer": `ServicesBlocks_serviceItemContainer__L3BU-`,
	"borderBottom": `ServicesBlocks_borderBottom__Csrgx`,
	"errorMessage": `ServicesBlocks_errorMessage__MIsqV`
};
export default ___CSS_LOADER_EXPORT___;
