// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimePickerForSchedule_container__Ybqr9 {
    background-color: var(--tg-theme-bg-color);
    /* background-color: aqua; */
    position: absolute;
    width: 180px;
    height: 207px;
    right: 60px;
    z-index: 80;
    border-radius: 13px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.TimePickerForSchedule_scrollContainer__a9XOm {
    margin: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;

}

.TimePickerForSchedule_scroll__gSuRY {
    width: 50%;
    height: calc(100% - 140px);
    overflow-y: scroll;
    padding-top: 70px;
    padding-bottom: 70px;
}

.TimePickerForSchedule_scroll__gSuRY::-webkit-scrollbar {
    width: 0;
}

.TimePickerForSchedule_timeItem__M7KIX {
    display: flex;
    height: 35px;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
            user-select: none;
    /* transition: transform 600ms cubic-bezier(0.13, 0.67, 0.01, 0.94); */
}

.TimePickerForSchedule_selectedContainer__9umrp {
    position: absolute;
    top: 70px;
    /* left: 16px; */
    background-color: rgba(116, 116, 128, 0.08);
    height: 35px;
    width: 148px;
    border-radius: 7px;
    pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/sсhedule/timePickerForSchedule/TimePickerForSchedule.module.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,4BAA4B;IAC5B,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,kBAAkB;;AAEtB;;AAEA;IACI,UAAU;IACV,0BAA0B;IAC1B,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,yBAAiB;YAAjB,iBAAiB;IACjB,sEAAsE;AAC1E;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,gBAAgB;IAChB,2CAA2C;IAC3C,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,oBAAoB;AACxB","sourcesContent":[".container {\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: aqua; */\n    position: absolute;\n    width: 180px;\n    height: 207px;\n    right: 60px;\n    z-index: 80;\n    border-radius: 13px;\n    display: flex;\n    justify-content: space-between;\n    overflow: hidden;\n}\n\n.scrollContainer {\n    margin: 16px;\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    position: relative;\n\n}\n\n.scroll {\n    width: 50%;\n    height: calc(100% - 140px);\n    overflow-y: scroll;\n    padding-top: 70px;\n    padding-bottom: 70px;\n}\n\n.scroll::-webkit-scrollbar {\n    width: 0;\n}\n\n.timeItem {\n    display: flex;\n    height: 35px;\n    align-items: center;\n    justify-content: center;\n    user-select: none;\n    /* transition: transform 600ms cubic-bezier(0.13, 0.67, 0.01, 0.94); */\n}\n\n.selectedContainer {\n    position: absolute;\n    top: 70px;\n    /* left: 16px; */\n    background-color: rgba(116, 116, 128, 0.08);\n    height: 35px;\n    width: 148px;\n    border-radius: 7px;\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TimePickerForSchedule_container__Ybqr9`,
	"scrollContainer": `TimePickerForSchedule_scrollContainer__a9XOm`,
	"scroll": `TimePickerForSchedule_scroll__gSuRY`,
	"timeItem": `TimePickerForSchedule_timeItem__M7KIX`,
	"selectedContainer": `TimePickerForSchedule_selectedContainer__9umrp`
};
export default ___CSS_LOADER_EXPORT___;
