import React, { useRef, useState, useEffect } from 'react';
import { Subheadline2Semibold } from '../../../textStyles/TextStyleComponents';
import cl from './ServiceDescription.module.css';
import validationStore from '../../../store/ValidationStore';

const Description = ({ serviceDescription, setServiceDescription }) => {
    const textareaRef = useRef(null);
    // const [text, setText] = useState(value);

    const adjustHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {            
            textarea.style.height = '0';
            
            const newScrollHeight = textarea.scrollHeight;
            
            textarea.style.height = Math.max(newScrollHeight, 44) + 'px'; 
        }
    };

    // const handleChange = (e) => {
    //     setText(e.target.value);
    //     if (onChange) onChange(e);
    // };

    useEffect(() => {        
        adjustHeight();
    }, [serviceDescription]);

    return (
        <div className={cl.container}>
            <Subheadline2Semibold className={cl.ttl}>Описание</Subheadline2Semibold>
            <textarea
                ref={textareaRef}
                className={cl.inpt}
                placeholder={'Расскажите, как будет проходить услуга'}
                value={serviceDescription}
                onChange={(e) => validationStore.handleServiceDescriptionInputChange(e, setServiceDescription)}
            />
            {/* {footnote && <Footnote className={cl.ftnt}>{footnote}</Footnote>} */}
        </div>
    );
};

export default Description;