// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderUniversal_header__qzdxt {
    margin-top: 30px;
    margin-bottom: 32px;
}

.HeaderUniversal_subHeadLine__R3fGG {    
    color: var(--tg-theme-hint-color);
}`, "",{"version":3,"sources":["webpack://./src/components/universalComponents/headerUniversal/HeaderUniversal.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".header {\n    margin-top: 30px;\n    margin-bottom: 32px;\n}\n\n.subHeadLine {    \n    color: var(--tg-theme-hint-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `HeaderUniversal_header__qzdxt`,
	"subHeadLine": `HeaderUniversal_subHeadLine__R3fGG`
};
export default ___CSS_LOADER_EXPORT___;
