// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListOfDaysForSсhedule_container__kXAWl {
    margin: 0 -15px;
    background-color: var(--tg-theme-bg-color);
}

`, "",{"version":3,"sources":["webpack://./src/components/sсhedule/listOfDaysForSсhedule/ListOfDaysForS%D1%81hedule.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,0CAA0C;AAC9C","sourcesContent":[".container {\n    margin: 0 -15px;\n    background-color: var(--tg-theme-bg-color);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ListOfDaysForSсhedule_container__kXAWl`
};
export default ___CSS_LOADER_EXPORT___;
