import React from "react";
import cl from "./AddServiceButton.module.css"
import { ReactComponent as AddNewServiceIcon } from "../../../icons/AddNewServiceIcon.svg"
import { useNavigate } from "react-router-dom"

const AddServiceButton = () => {
    const navigate = useNavigate()
    const handleOnClick = () => {
        navigate(`/addingOrChangeService/new`);
    }

    return (
        <div className={cl.container}>
            <button className={cl.btn} onClick={handleOnClick}>                
                <AddNewServiceIcon/>                           
            </button>
        </div>
    )
}

export default AddServiceButton