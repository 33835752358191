import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Instructions from './pages/instructions/Instructions';
import ProfileSettings from './pages/profileSettings/ProfileSettings';
import ServicesPage from './pages/servicesPage/ServicesPage';
import Sсhedule from './pages/sсhedule/Sсhedule';
import AddingOrChangeService from './pages/addingOrChangeService/AddingOrChangeService';
import ScheduleTemplates from './pages/scheduleTemplates/ScheduleTemplates';
import SingleTemplatePage from './components/sсhedule/templatesForSchedule/singleTemplatePage/SingleTemplatePage';
import SingleDayOfSchedulePage from './components/sсhedule/listOfDaysForSсhedule/singleDayOfSchedulePage/SingleDayOfSchedulePage';
import BotSettings from './pages/botSettings/BotSettings';
import Payment from './pages/payment/Payment';
import ErrorPage from './pages/errorPage/ErrorPage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<ProfileSettings />} />
          <Route path="/instructions" element={<Instructions />} />
          <Route path="/botSettings" element={<BotSettings />} />
          <Route path="/servicesPage" element={<ServicesPage />} />
          <Route path="/addingOrChangeService/:id" element={<AddingOrChangeService/>} />
          <Route path="/sсhedule" element={<Sсhedule/>} />
          <Route path="/scheduleTemplates" element={<ScheduleTemplates/>} />
          <Route path="/template/:id" element={<SingleTemplatePage/>} />
          <Route path="/dayOfSchedule/:date" element={<SingleDayOfSchedulePage/>} />
          
          <Route path='/errorPage' element={<ErrorPage/>}/>
          <Route path='/payment' element={<Payment/>}/>
          {/* <Route path="/info/:id" element={<Info />} />
          <Route path="/selectTime" element={<SelectTime />} />
          <Route path="/lastStep" element={<LastStep />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
