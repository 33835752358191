import { HeadlineBody } from "../../../../../textStyles/TextStyleComponents";
import cl from "./GapForTemplate.module.css"
import { useState } from "react";
import TimePickerForSchedule from "../../../timePickerForSchedule/TimePickerForSchedule";

const GapForTemplate = ({ gap, gapIndex, onChangeGap, showDeleteButton, onDeleteGap, isLastGap }) => {
    const containerStyle = isLastGap ? { marginBottom: '10px' } : { marginBottom: '15px' };

    const [showStartTimePicker, setShowStartTimePicker] = useState(false);
    const [showEndTimePicker, setShowEndTimePicker] = useState(false);


    return (
        <div style={containerStyle}>
            <div className={cl.inptContainer}>
                <HeadlineBody>Начало работы</HeadlineBody>

                <input
                    className={cl.inpt}
                    type="time"
                    value={gap.start}
                    onChange={(e) => onChangeGap(gapIndex, 'start', e.target.value)}
                />

                {/* <div onClick={() => setShowStartTimePicker(!showStartTimePicker)} className={cl.timePickerContainer}>
                    <HeadlineBody>
                        {gap.start}
                    </HeadlineBody>
                    {showStartTimePicker &&
                        <TimePickerForSchedule
                            initialTime={gap.start}
                            onTimeSelect={(newTime) => onChangeGap(gapIndex, 'start', newTime)}
                            onClose={() => setShowStartTimePicker(false)}
                        />
                    }
                </div> */}
                <div className={cl.borderBottom}></div>
            </div>



            <div className={cl.inptContainer}>
                <HeadlineBody>Конец работы</HeadlineBody>
                <input
                    className={cl.inpt}
                    type="time"
                    value={gap.end}
                    onChange={(e) => onChangeGap(gapIndex, 'end', e.target.value)}
                />
                {showDeleteButton && (
                    <div className={cl.borderBottom}></div>
                )}
            </div>



            {showDeleteButton && (
                <button className={cl.btn} onClick={() => onDeleteGap(gapIndex)}>
                    Удалить
                </button>
            )}

        </div>
    );
};

export default GapForTemplate;