import React from "react";
import { HeadLineSemibold } from "../../../textStyles/TextStyleComponents";
import cl from "./InputUniversal.module.css"
import { Footnote } from "../../../textStyles/TextStyleComponents";
import validationStore from "../../../store/ValidationStore";

const InputUniversal = ({ title, value, setValue, placeholder, footnote }) => {
    const containerClass = `${cl.container} ${footnote ? cl.containerWithFootnote : ''}`;

    return (
        <div className={containerClass}>
            <HeadLineSemibold className={cl.ttl}>{title}</HeadLineSemibold>
            <input
                className={cl.inpt}
                placeholder={placeholder}
                value={value}
                onChange={(e) => validationStore.handleReguralInputChange(e, setValue)}
            />
            {footnote && <Footnote className={cl.ftnt}>{footnote}</Footnote>}
        </div>
    )
}

export default InputUniversal