import cl from "./DualInputDualButton.module.css"
import { ReactComponent as Disclosure } from "../../../icons/Disclosure.svg"
import { useState, useRef, useEffect } from "react"
import { HeadlineBody } from "../../../textStyles/TextStyleComponents"
import Duration from "./duration/Duration"
import TimeInput from "./timeInput/TimeInput"
import validationStore from "../../../store/ValidationStore"

const DualInputDualButton = ({ serviceName, setServiceName, serviceCategory, setServiceCategory, serviceDuration, setServiceDuration, servicePrice, setServicePrice, categories }) => {
    const tg = window.Telegram.WebApp;

    const [showDropdown, setShowDropdown] = useState(false);

    const handleSelectCategory = (category) => {
        setServiceCategory(category);
        setShowDropdown(false);
    };

    const handleChangeServicePrice = (e) => {
        const value = e.target.value;
        // Удаляем все кроме чисел и точки, и символ рубля
        const numericValue = value.replace(/[^0-9.]/g, '');

        if (numericValue.length > validationStore.maxServicePriceInputLength) {
            setTimeout(() => {
                inputRef.current.setSelectionRange(servicePrice.length - 2, servicePrice.length - 2);
            }, 0);
            return;
        }

        const cursorPosition = e.target.selectionStart;

        setServicePrice(numericValue ? numericValue + " ₽" : '');

        setTimeout(() => {
            if (inputRef.current && numericValue) {
                inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
            }
        }, 0);
    }

    const inputRef = useRef(null);

    const handleKeyDown = (e) => {
        const { selectionStart, selectionEnd } = e.target;

        // Запретить перемещение курсора за пределы разрешенного диапазона с клавиатуры
        if ((selectionStart >= servicePrice.length - 2 && selectionEnd >= servicePrice.length - 2) &&
            (e.key === "ArrowRight" || e.key === "End")) {
            e.preventDefault();
        }
        if ((selectionStart <= servicePrice.length - 2 && selectionEnd <= servicePrice.length - 2) &&
            (e.key === "ArrowLeft" || e.key === "Home")) {
            e.preventDefault();
        }
    };

    const handleClick = (e) => {
        // Предотвратить установку курсора за последними двумя символами при клике мышью
        const { selectionStart, selectionEnd } = inputRef.current;

        if (selectionStart > servicePrice.length - 2 || selectionEnd > servicePrice.length - 2) {
            inputRef.current.setSelectionRange(servicePrice.length - 2, servicePrice.length - 2);
            e.preventDefault(); // Предотвратить дальнейшее событие клика
        }
    };

    const inputCategoryOnFocus = () => {
        if (categories.length !== 0) setShowDropdown(true);
    }


    return (
        <div className={cl.container}>
            <div className={cl.serviceContainer}>
                <input
                    className={`${cl.serviceInput} ${cl.serviceName}`}
                    placeholder={"Название услуги"}
                    value={serviceName}                    
                    onChange={(e) => validationStore.handleReguralInputChange(e, setServiceName)}
                />
                <div className={cl.borderBottom}></div>
            </div>

            <div className={cl.serviceContainer}>
                <input
                    className={cl.serviceInput}
                    placeholder="Название категории"
                    value={serviceCategory}
                    onChange={(e) => validationStore.handleReguralInputChange(e, setServiceCategory)}                    
                    onFocus={inputCategoryOnFocus}
                    onBlur={() => setShowDropdown(false)}
                />
                {showDropdown && (
                    <div className={`${cl.dropdown} ${tg.colorScheme === 'dark' ? cl.darkBorder : cl.lightBorder}`}>
                        {categories.filter(category => category.trim() !== '').map((category, index) => (
                            <div
                                key={index}
                                onMouseDown={() => handleSelectCategory(category)}
                            >
                                <div className={`${cl.dropdownItem} ${index === categories.length - 1 ? cl.noBorderBottom : ''}`}>
                                    <HeadlineBody>{category}</HeadlineBody>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className={cl.borderBottom}></div>
            </div>

            {/* <div className={cl.serviceContainer}>
                <Duration serviceDuration={serviceDuration} />
            </div> */}

            <div className={cl.serviceContainer}>
                <TimeInput serviceDuration={serviceDuration} setServiceDuration={setServiceDuration} servicePrice={servicePrice} serviceName={serviceName} serviceCategory={serviceCategory}/>
                <div className={cl.borderBottom}></div>
            </div>

            <div className={cl.priceInput}>
                <input
                    inputMode="numeric"
                    className={`${cl.serviceInput} ${cl.servicePrice}`}
                    placeholder={"Цена ₽"}
                    value={servicePrice}
                    onChange={handleChangeServicePrice}

                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    onClick={handleClick}
                    onMouseUp={handleClick}
                />

            </div>
        </div>
    )
}

export default DualInputDualButton