// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicePrice_container__V4asZ {
    margin-bottom: 10px;
}

.ServicePrice_ttl__6gdAL {
    color: var(--tg-theme-hint-color);
    margin-left: 16px;
}

.ServicePrice_priceInput__0k131 {
    background-color: var(--tg-theme-bg-color);
    /* background-color: aqua;  */
    border-radius: none;
    height: 44px;
    border: none;
    color: var(--tg-theme-text-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
    outline: none;

    margin-top: 7px;

    border-radius: 10px;

    padding-left: 16px;

    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
}

.ServicePrice_priceInput__0k131::placeholder {
    color: var(--tg-theme-hint-color);
}`, "",{"version":3,"sources":["webpack://./src/components/addingOrChangeService/servicePrice/ServicePrice.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,iBAAiB;AACrB;;AAEA;IACI,0CAA0C;IAC1C,6BAA6B;IAC7B,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;IAC7B,aAAa;;IAEb,eAAe;;IAEf,mBAAmB;;IAEnB,kBAAkB;;IAElB,WAAW;IACX,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".container {\n    margin-bottom: 10px;\n}\n\n.ttl {\n    color: var(--tg-theme-hint-color);\n    margin-left: 16px;\n}\n\n.priceInput {\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: aqua;  */\n    border-radius: none;\n    height: 44px;\n    border: none;\n    color: var(--tg-theme-text-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n    outline: none;\n\n    margin-top: 7px;\n\n    border-radius: 10px;\n\n    padding-left: 16px;\n\n    width: 100%;\n    box-sizing: border-box;\n    max-width: 100%;\n}\n\n.priceInput::placeholder {\n    color: var(--tg-theme-hint-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ServicePrice_container__V4asZ`,
	"ttl": `ServicePrice_ttl__6gdAL`,
	"priceInput": `ServicePrice_priceInput__0k131`
};
export default ___CSS_LOADER_EXPORT___;
