// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Payment_headlineContainer__pUAIw {
    
}

/* body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.container {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

button {
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

.payform-tinkoff {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 2px auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 250px;
}
.payform-tinkoff-row {
    margin: 2px;
    border-radius: 4px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border: 1px solid #DFE3F3;
    padding: 15px;
    outline: none;
    background-color: #DFE3F3;
    font-size: 15px;
}
.payform-tinkoff-row:focus {
    background-color: #FFFFFF;
    border: 1px solid #616871;
    border-radius: 4px;
}
.payform-tinkoff-btn {
    background-color: #FBC520;
    border: 1px solid #FBC520;
    color: #3C2C0B;
}
.payform-tinkoff-btn:hover {
    background-color: #FAB619;
    border: 1px solid #FAB619;
} */`, "",{"version":3,"sources":["webpack://./src/pages/payment/Payment.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAyEG","sourcesContent":[".headlineContainer {\n    \n}\n\n/* body {\n    font-family: Arial, sans-serif;\n    background-color: #f4f4f4;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    margin: 0;\n}\n\n.container {\n    text-align: center;\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\nbutton {\n    padding: 10px 20px;\n    font-size: 16px;\n    margin-top: 20px;\n    cursor: pointer;\n    border: none;\n    background-color: #007BFF;\n    color: white;\n    border-radius: 5px;\n    transition: background-color 0.3s ease;\n}\n\nbutton:hover {\n    background-color: #0056b3;\n}\n\n.payform-tinkoff {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    margin: 2px auto;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    -ms-flex-direction: column;\n    flex-direction: column;\n    max-width: 250px;\n}\n.payform-tinkoff-row {\n    margin: 2px;\n    border-radius: 4px;\n    -webkit-box-flex: 1;\n    -ms-flex: 1;\n    flex: 1;\n    -webkit-transition: 0.3s;\n    -o-transition: 0.3s;\n    transition: 0.3s;\n    border: 1px solid #DFE3F3;\n    padding: 15px;\n    outline: none;\n    background-color: #DFE3F3;\n    font-size: 15px;\n}\n.payform-tinkoff-row:focus {\n    background-color: #FFFFFF;\n    border: 1px solid #616871;\n    border-radius: 4px;\n}\n.payform-tinkoff-btn {\n    background-color: #FBC520;\n    border: 1px solid #FBC520;\n    color: #3C2C0B;\n}\n.payform-tinkoff-btn:hover {\n    background-color: #FAB619;\n    border: 1px solid #FAB619;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headlineContainer": `Payment_headlineContainer__pUAIw`
};
export default ___CSS_LOADER_EXPORT___;
