// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServiceDescription_container__soyUK {
    margin-bottom: 20px;
}

.ServiceDescription_ttl__YIJyf {
    color: var(--tg-theme-hint-color);
    margin-left: 16px;
}

.ServiceDescription_inpt__Rjds1 {
    background-color: var(--tg-theme-bg-color);
    /* background-color: aqua; */
    
    border: none;
    color: var(--tg-theme-text-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
    outline: none;
    border-radius: 10px;    
    margin-top: 7px;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;

    min-height: 44px;
    
    resize: none; 
    
    padding: 11px 16px;
}

.ServiceDescription_inpt__Rjds1::placeholder {
    color: var(--tg-theme-hint-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/addingOrChangeService/serviceDescription/ServiceDescription.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,iBAAiB;AACrB;;AAEA;IACI,0CAA0C;IAC1C,4BAA4B;;IAE5B,YAAY;IACZ,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,eAAe;;IAEf,gBAAgB;;IAEhB,YAAY;;IAEZ,kBAAkB;AACtB;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".container {\n    margin-bottom: 20px;\n}\n\n.ttl {\n    color: var(--tg-theme-hint-color);\n    margin-left: 16px;\n}\n\n.inpt {\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: aqua; */\n    \n    border: none;\n    color: var(--tg-theme-text-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n    outline: none;\n    border-radius: 10px;    \n    margin-top: 7px;\n    width: 100%;\n    box-sizing: border-box;\n    max-width: 100%;\n\n    min-height: 44px;\n    \n    resize: none; \n    \n    padding: 11px 16px;\n}\n\n.inpt::placeholder {\n    color: var(--tg-theme-hint-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ServiceDescription_container__soyUK`,
	"ttl": `ServiceDescription_ttl__YIJyf`,
	"inpt": `ServiceDescription_inpt__Rjds1`
};
export default ___CSS_LOADER_EXPORT___;
