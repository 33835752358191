// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhotoUploadButton_btn__wO-FO {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--tg-theme-bg-color);
    /* background-color: aqua; */
    height: 44px;
    border: none;

    color: var(--tg-theme-link-color);
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.43px;
    font-family: 'SF Pro Regular';
    border-radius: 10px;

    /* margin-top: 7px; */

    padding: 0 16px;

    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/addingOrChangeService/servicePhotosEditor/photoUploadButton/PhotoUploadButton.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,0CAA0C;IAC1C,4BAA4B;IAC5B,YAAY;IACZ,YAAY;;IAEZ,iCAAiC;IACjC,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,6BAA6B;IAC7B,mBAAmB;;IAEnB,qBAAqB;;IAErB,eAAe;;IAEf,WAAW;IACX,sBAAsB;IACtB,eAAe;AACnB","sourcesContent":[".btn {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: var(--tg-theme-bg-color);\n    /* background-color: aqua; */\n    height: 44px;\n    border: none;\n\n    color: var(--tg-theme-link-color);\n    font-size: 17px;\n    line-height: 22px;\n    letter-spacing: -0.43px;\n    font-family: 'SF Pro Regular';\n    border-radius: 10px;\n\n    /* margin-top: 7px; */\n\n    padding: 0 16px;\n\n    width: 100%;\n    box-sizing: border-box;\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `PhotoUploadButton_btn__wO-FO`
};
export default ___CSS_LOADER_EXPORT___;
