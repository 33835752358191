import { makeObservable, observable, action } from 'mobx';
import axios from 'axios';
// import servicesDataFromMyJson from "../Services.json"
// import servicesDataFromMyJson from "../ServicesPhoto.json"
import timeDataFromMyJson from "../Time.json"

class GetStore {
    servicesData = {};
    timeData = [];
    initialTimeData = [];

    isServicesDataLoaded = false;

    version = null;

    isTimeDataLoaded = false;

    // currentTimeData = [...this.timeData];

    constructor() {
        makeObservable(this, {
            servicesData: observable,
            timeData: observable,
            initialTimeData: observable,
            // currentTimeData: observable,
            isServicesDataLoaded: observable,
            version: observable,
            isTimeDataLoaded: observable,
            setServicesData: action,            
            setTimeData: action,            
            // setCurrentTimeData: action,
            // updateCurrentTimeData: action,
            updateOrAddDay: action,
        }, { deep: true });
    }

    setServicesData = (newData) => {
        this.servicesData = newData;
    }

    setTimeData = (newData) => {
        this.timeData = newData;
    }

    // setCurrentTimeData = (newData) => {
    //     this.currentTimeData = newData;
    // }

    // updateCurrentTimeData = () => {
    //     this.currentTimeData = this.timeData;        
    // }




    updateOrAddDay = (newDay) => {
        const index = this.timeData.findIndex(d => d.date === newDay.date);
        let updatedTimeData;

        if (index > -1) {
            // Создаем новый массив, где один из дней обновлен
            updatedTimeData = this.timeData.map((item, idx) => {
                if (idx === index) {
                    return newDay; // Возвращает обновленный день
                }
                return item; // Возвращает неизмененные дни
            });
        } else {
            // Создаем новый массив, добавляя новый день
            updatedTimeData = [...this.timeData, newDay];
            // Сортировка updatedTimeData по дате
            updatedTimeData.sort((a, b) =>
                new Date(a.date) - new Date(b.date)
            );
        }

        // Используем setCurrentTimeData для обновления состояния
        this.setTimeData(updatedTimeData);
    }

}

const getStore = new GetStore();

export default getStore;
