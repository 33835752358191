// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhotosSwiper_containerSwiper__fcQZr {
    position: relative;
    height: 270px;
    /* width: calc(100% + 30px); */
    background-color: var(--tg-theme-section-bg-color);
    margin-top: 15px;
    border-radius: 10px;
}

.PhotosSwiper_mySwiper__kzJtL {
    width: 100%;
    height: 100%;
}

.PhotosSwiper_swiperSlide__uSmZq {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.PhotosSwiper_swiperSlideImg__MoZcL {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.PhotosSwiper_mySwiper__kzJtL {
    margin-left: auto;
    margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/addingOrChangeService/servicePhotosEditor/photosSwiper/PhotosSwiper.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,8BAA8B;IAC9B,kDAAkD;IAClD,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,eAAe;;IAEf,iCAAiC;IACjC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".containerSwiper {\n    position: relative;\n    height: 270px;\n    /* width: calc(100% + 30px); */\n    background-color: var(--tg-theme-section-bg-color);\n    margin-top: 15px;\n    border-radius: 10px;\n}\n\n.mySwiper {\n    width: 100%;\n    height: 100%;\n}\n\n.swiperSlide {\n    text-align: center;\n    font-size: 18px;\n\n    /* Center slide text vertically */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.swiperSlideImg {\n    display: block;\n    height: 100%;\n    width: 100%;\n    object-fit: contain;\n}\n\n.mySwiper {\n    margin-left: auto;\n    margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerSwiper": `PhotosSwiper_containerSwiper__fcQZr`,
	"mySwiper": `PhotosSwiper_mySwiper__kzJtL`,
	"swiperSlide": `PhotosSwiper_swiperSlide__uSmZq`,
	"swiperSlideImg": `PhotosSwiper_swiperSlideImg__MoZcL`
};
export default ___CSS_LOADER_EXPORT___;
