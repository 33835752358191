import cl from './ListGapsOfSchedule.module.css'
import { HeadlineBody } from '../../../../../textStyles/TextStyleComponents';
import templateStore from '../../../../../store/TemplatesStore';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

const ListGapsOfSchedule = observer(({ gaps, setGaps, setSelectedTemplateId }) => {

    const onChangeGap = (gapIndex, field, value) => {
        setGaps((prevGaps) => {
            const newGaps = prevGaps.map((gap, index) => {
                if (index === gapIndex) {
                    return { ...gap, [field]: value };
                }
                return gap;
            });
            return newGaps;
        });
    };

    const onDeleteGap = (gapIndex) => {
        setGaps((prevGaps) => {
            const newGaps = prevGaps.filter((_, index) => index !== gapIndex);
            return newGaps;
        });
    };

    const onAddGap = () => {
        const lastGap = gaps[gaps.length - 1];
        const newStart = lastGap ? addHour(lastGap.end) : "09:00";
        const newEnd = addHour(newStart);
        const newGap = { start: newStart, end: newEnd };

        setGaps((prevGaps) => [...prevGaps, newGap]);
    };

    // Функция для добавления часа к времени
    const addHour = (time) => {
        const [hours, minutes] = time.split(":");
        let newHours = parseInt(hours, 10) + 1;
        if (newHours < 10) {
            newHours = "0" + newHours;
        } else if (newHours >= 24) {
            newHours = "00";
        }
        return `${newHours}:${minutes}`;
    };

    useEffect(() => {
        // Функция для сравнения gaps с шаблонами
        const findMatchingTemplate = (gaps) => {
            const template = templateStore.templatesData.find((template) => {
                if (template.gaps.length !== gaps.length) {
                    return false;
                }
                for (let i = 0; i < gaps.length; i++) {
                    if (template.gaps[i].start !== gaps[i].start || template.gaps[i].end !== gaps[i].end) {
                        return false;
                    }
                }
                return true;
            });

            if (template) {
                setSelectedTemplateId(template.id);
            } else {
                setSelectedTemplateId(0);
            }
        };

        findMatchingTemplate(gaps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gaps]);

    return (
        <div>
            {gaps.map((gap, index) => (
                <div key={index} className={cl.gapItem}>
                    <div className={cl.inptContainer}>
                        <HeadlineBody>Начало:</HeadlineBody>
                        <input
                            className={cl.inpt}
                            type="time"
                            value={gap.start}
                            onChange={(e) => onChangeGap(index, 'start', e.target.value)}
                        />
                        <div className={cl.borderBottom}></div>
                    </div>

                    <div className={cl.inptContainer}>
                        <HeadlineBody>Конец:</HeadlineBody>
                        <input
                            className={cl.inpt}
                            type="time"
                            value={gap.end}
                            onChange={(e) => onChangeGap(index, 'end', e.target.value)}
                        />
                        {gaps.length > 1 &&
                            <div className={cl.borderBottom}></div>
                        }
                    </div>
                    {gaps.length > 1 &&
                        <button className={`${cl.btn} ${cl.deleteBtn}`} onClick={() => onDeleteGap(index)}>Удалить</button>
                    }
                </div>
            ))}

            {gaps.length <= 2 && 
                <button className={`${cl.btn} ${cl.addBtn}`} onClick={onAddGap}>Добавить промежуток</button>
            }
        </div>
    )
})

export default ListGapsOfSchedule