import { HeadlineBody, HeadLineSemibold } from '../../../../textStyles/TextStyleComponents';
import cl from './ModalWindowToConfirmServiceDeletion.module.css';
import { useEffect } from 'react';

const ModalWindowToConfirmServiceDeletion = ({ onConfirm, onCancel }) => {
    const handleBackdropClick = (event) => {
        if (event.currentTarget === event.target) {
            onCancel();
        }
    };
    
    useEffect(() => {
        // При монтировании компонента
        document.body.style.overflow = 'hidden';

        // При демонтировании компонента
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    return (
        <div className={cl.backdrop} onClick={handleBackdropClick}>
            <div className={cl.modal}>
                <div className={cl.text}>
                    <HeadLineSemibold>Удалить услугу?</HeadLineSemibold>
                </div>
                <div className={cl.btnContaier}>
                    <button className={`${cl.btn} ${cl.confirmBtn}`} onClick={onConfirm}>
                        <HeadlineBody>Удалить</HeadlineBody>
                    </button>
                    <button className={`${cl.btn} ${cl.cancelBtn}`} onClick={onCancel}>
                        <HeadLineSemibold>Назад</HeadLineSemibold>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalWindowToConfirmServiceDeletion;