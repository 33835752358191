import cl from './Payment.module.css'
import { useEffect } from 'react';
import HeaderUniversal from "../../components/universalComponents/headerUniversal/HeaderUniversal";
import { HeadlineBody, HeadLineSemibold } from '../../textStyles/TextStyleComponents';

const Payment = () => {
    const tg = window.Telegram.WebApp;
    const params = new URLSearchParams(window.location.search);
    const plan = params.get('plan');

    let amount;
    let amountText;

    if (plan === 'ANNUAL') {
        amount = '2940';
        amountText = 'Подписка на 1 год';
    } else {
        amount = '349';
        amountText = 'Подписка на 1 месяц';
    }

    // useEffect(() => {
    //     const initData = tg.initData;

    //     const parseInitData = (initData) => {
    //         return Object.fromEntries(new URLSearchParams(initData));
    //     };

    //     const parsedData = parseInitData(initData);

    //     localStorage.setItem('botUsername', JSON.parse(parsedData.user).id);

    //     if (!tg.isClosingConfirmationEnabled) tg.enableClosingConfirmation();

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    const handleSubmit = (event) => {
        event.preventDefault(); // Предотвращаем стандартное поведение формы
        window.pay(event.target); // Вызов функции оплаты
    };

    return (
        <div>
            <HeaderUniversal>Подписка GROS Service</HeaderUniversal>

            <div className={cl.headlineContainer}>
                <HeadlineBody>
                    Вы выбрали оформить подписку GROS Service на <b>1 месяц за 349 рублей</b>
                </HeadlineBody>

                
            </div>

            <script src="https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js"></script>
            <form className="payform-tinkoff" name="payform-tinkoff" onSubmit="pay(this); return false;">
                <input className="payform-tinkoff-row" type="hidden" name="terminalkey" value="1724060448946DEMO" />
                <input className="payform-tinkoff-row" type="hidden" name="frame" value="false" />
                <input className="payform-tinkoff-row" type="hidden" name="language" value="ru" />
                <input className="payform-tinkoff-row" type="text" placeholder="Сумма заказа" name="amount" value={amount} required disabled />
                <input className="payform-tinkoff-row" type="hidden" placeholder="Номер заказа" name="order" />
                <input className="payform-tinkoff-row" type="text" placeholder="Описание заказа" name="description" value={amountText} disabled />
                <input className="payform-tinkoff-row" type="text" placeholder="ФИО" name="name" required />
                <input className="payform-tinkoff-row" type="hidden" placeholder="E-mail" name="email" />
                <input className="payform-tinkoff-row" type="hidden" name="reccurentPayment" value="true" />
                <input className="payform-tinkoff-row" type="hidden" name="customerKey" value={localStorage.getItem('botUsername')} />
                <input className="payform-tinkoff-row" type="tel" placeholder="Контактный телефон" name="phone" required />
                <input className="payform-tinkoff-row payform-tinkoff-btn" type="submit" value="Оплатить" />
            </form>

        </div>
    );
};

export default Payment