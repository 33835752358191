import { HeadlineBody } from "../../../../textStyles/TextStyleComponents";
import cl from "./Duration.module.css"
import { useState } from "react";
import DurationTimePicker from "./durationTimePicker/DurationTImePicker";

const Duration = ({ serviceDuration }) => {

    const [isMessageVisible, setMessageVisible] = useState(false);

    function toggleMessage() {
        setMessageVisible(!isMessageVisible);
    }

    const moment = require('moment');

    function formatMinutesToTimeString(minutes) {
        const duration = moment.duration(minutes, 'minutes');
        const hours = duration.hours();
        const mins = duration.minutes();

        let result = '';
        if (hours > 0) {
            result += hours + ' час';
            if (hours > 1) result += 'а';
            result += ' '
        }
        if (mins > 0) {
            result += mins + ' минут';
        }
        return result;
    }

    return (
        <div className={cl.container}>
            <button className={cl.btn} onClick={toggleMessage}>
                <HeadlineBody className={cl.durationWord}>Длительность</HeadlineBody>

                <HeadlineBody className={cl.durationInscription}>{formatMinutesToTimeString(serviceDuration)}</HeadlineBody>
                
            </button>
            
            {/* <div className={cl.borderBottom}></div> */}

            {isMessageVisible &&
                <DurationTimePicker/>
            }

            <div className={cl.borderBottom}></div>
        </div>
    )
}

export default Duration