import DeletePhotoButton from './deletePhotoButton/DeletePhotoButton'
import PhotosSwiper from './photosSwiper/PhotosSwiper'
import PhotoUploadButton from './photoUploadButton/PhotoUploadButton'
import cl from './ServicePhotosEditor.module.css'
import { useState } from 'react'

const ServicePhotosEditor = ({ servicePhotos, setServicePhotos, isAddingNewService }) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.realIndex);
    };

    const removePhotoAtIndex = (index) => {
        const newPhotos = [...servicePhotos];
        newPhotos.splice(index, 1);
        setServicePhotos(newPhotos);
    };

    return (
        <div>
            <PhotoUploadButton
                body={"Загрузить фотографию"}
                setServicePhotos={setServicePhotos}
                isAddingNewService={isAddingNewService}
            />

            {servicePhotos.length > 0 && (
                <PhotosSwiper servicePhotos={servicePhotos} handleSlideChange={handleSlideChange} isAddingNewService={isAddingNewService} />
            )}

            {servicePhotos.length > 0 && (
                <DeletePhotoButton
                    removePhotoAtIndex={removePhotoAtIndex}
                    activeIndex={activeIndex}
                    body={"Удалить это фото"}
                />
            )}

        </div>
    )
}

export default ServicePhotosEditor