import { useState, useEffect } from 'react';
import cl from "./DayForSсhedule.module.css"
import Switch from 'react-ios-switch';
import { observer } from "mobx-react-lite"
import { Caption2, HeadlineBody } from '../../../../textStyles/TextStyleComponents';
import { useNavigate } from 'react-router-dom';
import getStore from '../../../../store/GetStore';
import templateStore from '../../../../store/TemplatesStore';
import ModalUniversal from '../../../universalComponents/modalUniversal/ModalUniversal';

const DayForSсhedule = observer(({ date, isLast }) => {
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate();

    const timeInterval = 15;

    const switchId = `switch-${date.year}-${String(date.month + 1).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;

    const monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
    const [switchState, setSwitchState] = useState(false);

    const [showSwitchToRestWithBusyCellModal, setShowSwitchToRestWithBusyCellModal] = useState(false);


    const findDayStatus = (year, month, day, time) => {
        const dateString = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        const dayInfo = time.find(d => d.date === dateString);
        return dayInfo ? dayInfo.status : null;
    };

    useEffect(() => {
        const status = findDayStatus(date.year, date.month, date.day, getStore.timeData);
        setSwitchState(status === "WORK");


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, getStore.timeData]);



    const handleClickToEditDay = () => {
        const newDate = `${date.year}-${String(date.month + 1).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
        navigate(`/dayOfSchedule/${newDate}`);
    }

    const handleLabelClick = (e) => {
        e.stopPropagation();
    };

    const toggleDayStatus = () => {
        const dateString = `${date.year}-${String(date.month + 1).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
        const newStatus = switchState ? 'REST' : 'WORK';
        let updated = false;

        const currentDay = getStore.timeData.find(item => item.date === dateString);

        // Если переключаем в 'REST' и есть хотя бы один 'busy' промежуток
        if (newStatus === 'REST' && currentDay && currentDay.recordingCells.some(cell => cell.status === 'BUSY')) {
            setShowSwitchToRestWithBusyCellModal(true); // Показываем модальное окно
            return; // Прерываем выполнение функции, не меняем статус
        }

        const template = templateStore.templatesData.find(t => t.id === 1);

        // Преобразуем gaps в recordingCells
        const recordingCells = template ? gapsToRecordingCells(template.gaps) : [];

        // Обновляем статус для существующего дня или добавляем новый день
        const updatedTime = getStore.timeData.map((item) => {
            if (item.date === dateString) {
                updated = true;
                return { ...item, status: newStatus };
            }
            return item;
        });

        // Если день не найден, добавляем его
        if (!updated) {
            updatedTime.push({
                date: dateString,
                status: newStatus,
                templateId: 1,
                recordingCells: recordingCells
            });
        }

        // Сортировка time по дате, возможно, потребуется корректировка в зависимости от формата даты
        updatedTime.sort((a, b) => new Date(a.date) - new Date(b.date));

        getStore.setTimeData(updatedTime);
        setSwitchState(!switchState);
        // console.log(switchState)
    };

    const gapsToRecordingCells = (gaps) => {
        const recordingCells = [];
        gaps.forEach(gap => {
            let [startHours, startMinutes] = gap.start.split(':').map(Number);
            let [endHours, endMinutes] = gap.end.split(':').map(Number);

            while (startHours < endHours || (startHours === endHours && startMinutes < endMinutes)) {
                recordingCells.push({
                    time: `${String(startHours).padStart(2, '0')}:${String(startMinutes).padStart(2, '0')}`,
                    status: 'FREE'
                });
                startMinutes += 15;
                if (startMinutes >= 60) {
                    startMinutes = 0;
                    startHours += 1;
                }
            }
        });
        return recordingCells;
    };

    const findTemplateById = (templateId) => {
        return templateStore.templatesData.find(template => template.id === templateId);
    };

    const calculateGaps = (cells) => {
        if (cells.length === 0) return [];

        let gaps = [];
        let start = cells[0].time;
        let end = null;

        for (let i = 1; i < cells.length; i++) {
            const current = cells[i];
            const previous = cells[i - 1];

            const currentTime = new Date(`1970-01-01T${current.time}:00`);
            const previousTime = new Date(`1970-01-01T${previous.time}:00`);
            const diff = (currentTime - previousTime) / 1000 / 60;

            if (diff > timeInterval) {
                end = new Date(previousTime.getTime() + timeInterval * 60000);
                gaps.push({ start, end: end.toTimeString().substring(0, 5) });
                start = current.time;
            }
        }

        const lastEnd = new Date(new Date(`1970-01-01T${cells[cells.length - 1].time}:00`).getTime() + timeInterval * 60000);
        gaps.push({ start, end: lastEnd.toTimeString().substring(0, 5) });

        return gaps;
    };

    const currentDay = getStore.timeData.find(item => item.date === `${date.year}-${String(date.month + 1).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`);
    const templateName = currentDay ? findTemplateById(currentDay.templateId)?.name || null : null;

    const timeRanges = currentDay
        ? currentDay.templateId ? findTemplateById(currentDay.templateId).gaps
            : calculateGaps(currentDay.recordingCells)
        : null;


    return (
        <div className={cl.container} onClick={handleClickToEditDay}>
            <div>
                <HeadlineBody className={`${(templateName || timeRanges) ? cl.data : ''}`}>
                    {`${date.day} ${monthNames[date.month]}, ${date.shortDayOfWeek}`}
                </HeadlineBody>

                {templateName &&
                    <Caption2 className={cl.templateName}>{templateName}</Caption2>
                }

                {timeRanges &&
                    <div className={cl.timeGaps}>
                        {timeRanges.map((gap, index) =>
                        (
                            <Caption2 key={index} className={cl.timeGap}>
                                {`${gap.start} - ${gap.end}`}
                            </Caption2>
                        ))}
                    </div>
                }
            </div>
            <label htmlFor={switchId} onClick={handleLabelClick}>
                <div>

                    <Switch
                        id={switchId} // Присваиваем свитчеру уникальный id
                        className={`${tg.colorScheme === 'dark' ? cl.customSwitchDark : cl.customSwitchLight} ${switchState ? cl.customSwitchActive : ''}`}
                        checked={switchState}
                        onChange={toggleDayStatus}
                    />

                </div>
            </label>
            {!isLast && <div className={cl.borderBottom}></div>}

            {showSwitchToRestWithBusyCellModal &&
                <ModalUniversal
                    text={'На этот день записался клиент. Чтобы сделать день выходным - отмените запись'}
                    setIsVisible={setShowSwitchToRestWithBusyCellModal}
                    cancelText={'Окей'}
                />
            }
        </div>
    );
})

export default DayForSсhedule;